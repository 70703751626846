/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ImageStructure = {
    imageBinary?: string;
    imageContent?: ImageStructure.imageContent;
    imageRef?: string;
};
export namespace ImageStructure {
    export enum imageContent {
        MAP = 'MAP',
        GRAPHIC = 'GRAPHIC',
        LOGO = 'LOGO',
    }
}

