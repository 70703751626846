/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserNeedStructure } from './UserNeedStructure';
export type SuitabilityStructure = {
    suitable?: SuitabilityStructure.suitable;
    userNeed?: UserNeedStructure;
};
export namespace SuitabilityStructure {
    export enum suitable {
        SUITABLE = 'SUITABLE',
        NOT_SUITABLE = 'NOT_SUITABLE',
    }
}

