import React, { useEffect } from 'react';

import { TipsDialogStep } from '../types/TipsDialogStep';
import { useStore } from '../setup/global-state';
import tip1Image from '../assets/images/tips-1.png';
import tip2Image from '../assets/images/tips-2.png';
import tip3Image from '../assets/images/tips-3.png';
import tip4Image from '../assets/images/tips-4.png';
import { ReactComponent as RouteIcon } from '../assets/icons/forms/route.svg';
import { ReactComponent as RainRadarIcon } from '../assets/icons/page-divider-buttons/rain-radar.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/page-divider-buttons/settings.svg';
import { RouteType } from '../types/RouteType';
import { TriasService } from '../services/TriasService';
import { RouteStructure } from '../services/api-routing';
import { CustomDialog } from './CustomDialog';
import { RainRadarControls } from './RainRadarControls';
import { SettingsControls } from './SettingsControls';
import { RouteSelectItem } from './RouteSelectItem';

export const TipsDialog = () => {
  const tipsDialogVisible = useStore((state) => state.tipsDialogVisible);
  const tipsDialogStep = useStore((state) => state.tipsDialogStep);
  const updateTipsDialogVisible = useStore((state) => state.setTipsDialogVisible);
  const updateTipsDialogStep = useStore((state) => state.setTipsDialogStep);
  const routes = useStore((state) => state.routes);

  useEffect(() => {
    if (tipsDialogVisible && tipsDialogStep === TipsDialogStep.DISABLED) {
      updateTipsDialogStep(TipsDialogStep.ROUTES);
    }
  });

  const handleClose = () => {
    updateTipsDialogVisible(false);
    setTimeout(() => updateTipsDialogStep(TipsDialogStep.DISABLED), 200);
  };

  return (
    <CustomDialog
      position={
        (
          {
            [TipsDialogStep.ROUTES]: 'right',
            [TipsDialogStep.RAIN_RADAR]: 'left',
            [TipsDialogStep.SETTINGS]: 'left',
          } as const
        )[tipsDialogStep as TipsDialogStep.ROUTES | TipsDialogStep.RAIN_RADAR | TipsDialogStep.SETTINGS]
      }
      open={tipsDialogVisible}
      onClose={handleClose}
      onNext={() => {
        if (tipsDialogStep < TipsDialogStep.WORK_IN_PROGRESS) {
          updateTipsDialogStep(tipsDialogStep + 1);
        } else {
          handleClose();
        }
      }}
      onSkip={handleClose}
      image={[tip1Image, tip2Image, tip3Image, tip4Image][tipsDialogStep - 1]}
      imageAlt={
        [
          'SchülerInnen auf dem Rad',
          'FahrradfahrerInnen prüfen das Regenradar',
          'Ein Fahrradfahrer ist zügig unterwegs',
          'Eine Fahrradfahrerin repariert ihr Rad',
        ][tipsDialogStep - 1]
      }
      title={['Wir moanens nur guad', 'Nimma noss werden', 'Foahr dei Tempo', 'Am werkln'][tipsDialogStep - 1]}
      content={
        [
          'Die kürzeste Radroute ist meistens nicht die Beste. Damit du möglichst gemütlich ans Ziel kommst beachten wir bei der Routenberechnung auch wie sicher und grün Streckenabschnitte sind. Stadt und Land Salzburg liefern dafür einzigartiges Insiderwissen.',
          'In Salzburg regnets ja gerne amal. Unser Niederschlagsradar hilft dir dabei möglichst trocken im Ziel anzukommen. Mit dem Schieberegler kannst du die Regenprognose und deine Route beobachten.',
          'Unsere Zeitangaben passen sich an deine Geschwindigkeit an - damit du deine Ankunftszeit perfekt planen kannst! Klick einfach auf das Zahnrad und gib dein ungefähres Tempo an.',
          'Diese Version der Radlkarte ist brandneu! Wenn dir etwas auffällt oder du Ideen und Wünsche hast: Wir freuen uns über jede Art von Anregung und Feedback. Melde dich einfach über die Kontakt-Seite. Wir beißen auch nicht. Versprochen.',
        ][tipsDialogStep - 1]
      }
      exampleIcon={
        [<RouteIcon key="route" />, <RainRadarIcon key="rain-radar" />, <SettingsIcon key="settings" />, undefined][
          tipsDialogStep - 1
        ]
      }
      exampleTitle={['Route', 'Regenradar', 'Einstellungen', undefined][tipsDialogStep - 1]}
      exampleComponent={
        [
          <RouteSelectItem
            key="route-select"
            routeType={RouteType.RECOMMENDED}
            route={TriasService.getRouteFromTrias(routes[RouteType.RECOMMENDED]) as RouteStructure}
          />,
          <RainRadarControls key="rain-radar" />,
          <SettingsControls key="settings" />,
          undefined,
        ][tipsDialogStep - 1]
      }
      examplePopper={tipsDialogStep !== TipsDialogStep.ROUTES}
      nextLabel={tipsDialogStep < TipsDialogStep.WORK_IN_PROGRESS ? 'Weiter' : 'Fertig'}
      skipLabel={tipsDialogStep < TipsDialogStep.WORK_IN_PROGRESS ? 'Beenden ohne Tipps' : undefined}
      numberOfSteps={4}
      currentStep={tipsDialogStep}
    />
  );
};
