import React, { Fragment, useState } from 'react';
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Box,
  Link as HyperLink,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as RadlkarteLogo } from '../assets/radlkarte-logo.svg';
import { ReactComponent as RadlkarteLogoMobile } from '../assets/radlkarte-logo-mobile.svg';
import { ReactComponent as Menu } from '../assets/icons/menu.svg';
import { footerLinks } from './Footer';

const useStyles = makeStyles((theme) => ({
  desktopLink: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: theme.shadows[1],
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  desktopLogo: {
    display: 'block',
    width: theme.spacing(16),
  },
  mobileWrapper: {
    order: 1,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileLink: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mobileLogo: {
    display: 'block',
    width: 'auto',
    height: theme.spacing(6),
  },
  menuIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  list: { minWidth: '50vw' },
  listText: {
    '& .MuiTypography-root': {
      color: theme.palette.text.primary,
    },
  },
}));

export const HeaderNavigation = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawerOpen = () => setDrawerOpen((prevState) => !prevState);
  const location = useLocation();

  return (
    <>
      <Link to="/" className={classes.desktopLink}>
        <RadlkarteLogo title="www.radlkarte.info" className={classes.desktopLogo} />
      </Link>

      <Box className={classes.mobileWrapper}>
        <AppBar position="relative" className={classes.appBar} elevation={1}>
          <Toolbar className={classes.toolbar}>
            <Link to="/" className={classes.mobileLink}>
              <RadlkarteLogoMobile title="www.radlkarte.info" className={classes.mobileLogo} />
            </Link>

            <IconButton onClick={toggleDrawerOpen} edge="end" color="inherit" aria-label="Menü öffnen" size="large">
              <Menu className={classes.menuIcon} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawerOpen}
          onOpen={toggleDrawerOpen}
          disableSwipeToOpen
        >
          <List className={classes.list}>
            {footerLinks
              .filter(({ to }) => location.pathname !== '/' || to !== '/')
              .map(({ title, to, href }) => (
                <Fragment key={to || href}>
                  {href && (
                    <ListItem
                      button
                      component={HyperLink}
                      href={href}
                      underline="none"
                      target="_blank"
                      referrerPolicy="no-referrer"
                    >
                      <ListItemText primary={title} className={classes.listText} />
                    </ListItem>
                  )}
                  {to && (
                    <ListItem button component={Link} to={to}>
                      <ListItemText primary={title} className={classes.listText} />
                    </ListItem>
                  )}
                </Fragment>
              ))}
          </List>
        </SwipeableDrawer>
      </Box>
    </>
  );
};
