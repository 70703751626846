/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VehicleTypeRefStructure } from './VehicleTypeRefStructure';
export type VehicleStatusStructure = {
    doorState?: VehicleStatusStructure.doorState;
    inPanic?: boolean;
    vehicleStopRequested?: boolean;
    vehicleTypeRef?: VehicleTypeRefStructure;
};
export namespace VehicleStatusStructure {
    export enum doorState {
        DOORS_OPEN = 'DOORS_OPEN',
        ALL_DOORS_CLOSED = 'ALL_DOORS_CLOSED',
    }
}

