import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ReactComponent as ToggleIcon } from '../assets/icons/page-divider-buttons/toggle.svg';
import { ReactComponent as RainRadarIcon } from '../assets/icons/page-divider-buttons/rain-radar.svg';
import { ReactComponent as LayersIcon } from '../assets/icons/page-divider-buttons/layers.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/page-divider-buttons/settings.svg';
import { TipsDialogStep } from '../types/TipsDialogStep';
import { useStore } from '../setup/global-state';
import { CustomIconButton } from './CustomIconButton';
import { RainRadarPopper } from './RainRadarPopper';
import { LayersPopper } from './LayersPopper';
import { SettingsPopper } from './SettingsPopper';
import { RainRadarOffsetChip } from './RainRadarOffsetChip';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: theme.spacing(50),
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: 'auto',
      top: '50%',
      right: 0,
      left: 'auto',
      transform: 'translate(50%, -50%)',
    },
  },
  tipsVisible: {
    [theme.breakpoints.up('md')]: {
      zIndex: 1500,
    },
  },
  button: {
    display: 'block',

    '&:not(:last-child)': {
      marginRight: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        marginRight: 0,
        marginBottom: theme.spacing(3),
      },
    },
  },
  menuButton: {
    '& svg': {
      width: `${theme.spacing(3)} !important`,
      height: `${theme.spacing(3)} !important`,
      transition: theme.transitions.create(['transform']),
      transform: ({ open }: { open: boolean }) => (open ? 'rotate(-180deg)' : 'rotate(0)'),

      [theme.breakpoints.up('md')]: {
        transform: ({ open }: { open: boolean }) => (open ? 'rotate(-90deg) translateY(-2px)' : 'rotate(90deg)'),
      },
    },
  },
  hidden: {
    filter: `blur(${theme.spacing(0.5)}) brightness(50%)`,
    pointerEvents: 'none',
  },
}));

type ControlsType = 'RAIN_RADAR' | 'LAYERS' | 'SETTINGS';

export const ControlButtons = () => {
  const anchorRainRadar = useRef<HTMLButtonElement>(null);
  const anchorLayers = useRef<HTMLButtonElement>(null);
  const anchorSettings = useRef<HTMLButtonElement>(null);
  const [anchor, setAnchor] = useState<{ type: ControlsType; anchor: HTMLButtonElement }>();

  const pageDividerOpen = useStore((state) => state.pageDividerOpen);
  const tipsDialogVisible = useStore((state) => state.tipsDialogVisible);
  const tipsDialogStep = useStore((state) => state.tipsDialogStep);
  const setPageDividerOpen = useStore((state) => state.setPageDividerOpen);
  const rainRadarPlaying = useStore((state) => state.rainRadarPlaying);

  const isTipsDialogActiveWithButton =
    tipsDialogVisible && tipsDialogStep >= TipsDialogStep.RAIN_RADAR && tipsDialogStep <= TipsDialogStep.SETTINGS;

  useEffect(() => {
    if (isTipsDialogActiveWithButton) {
      setAnchor({
        type: ({ [TipsDialogStep.RAIN_RADAR]: 'RAIN_RADAR', [TipsDialogStep.SETTINGS]: 'SETTINGS' } as const)[
          tipsDialogStep as TipsDialogStep.RAIN_RADAR | TipsDialogStep.SETTINGS
        ],
        anchor: (
          {
            [TipsDialogStep.RAIN_RADAR]: anchorRainRadar.current,
            [TipsDialogStep.SETTINGS]: anchorSettings.current,
          } as const
        )[tipsDialogStep as TipsDialogStep.RAIN_RADAR | TipsDialogStep.SETTINGS] as HTMLButtonElement,
      });
    } else {
      setAnchor(undefined);
    }
  }, [isTipsDialogActiveWithButton, tipsDialogStep]);

  const classes = useStyles({ open: pageDividerOpen });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (type: ControlsType, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isTipsDialogActiveWithButton) {
      return;
    }

    setAnchor((prevState) =>
      prevState?.type === type
        ? undefined
        : {
            type,
            anchor: event.currentTarget,
          },
    );

    if (matches && pageDividerOpen) {
      setPageDividerOpen(false);
    }
  };

  const handleClose = () => {
    if (!isTipsDialogActiveWithButton) {
      setAnchor(undefined);
    }
  };

  return (
    <Box className={clsx(classes.root, isTipsDialogActiveWithButton && classes.tipsVisible)}>
      <CustomIconButton
        title={pageDividerOpen ? 'Routeneingabe schließen' : 'Routeneingabe öffnen'}
        className={clsx(classes.button, classes.menuButton, isTipsDialogActiveWithButton && classes.hidden)}
        onClick={() => setPageDividerOpen(!pageDividerOpen)}
      >
        <ToggleIcon />
      </CustomIconButton>

      {[
        {
          Icon: RainRadarIcon,
          type: 'RAIN_RADAR' as const,
          title: 'Regenradar',
          Controls: RainRadarPopper,
          ref: anchorRainRadar,
          tipsDialogStep: TipsDialogStep.RAIN_RADAR,
        },
        { Icon: LayersIcon, type: 'LAYERS' as const, title: 'Ebenen', Controls: LayersPopper, ref: anchorLayers },
        {
          Icon: SettingsIcon,
          type: 'SETTINGS' as const,
          title: 'Einstellungen',
          Controls: SettingsPopper,
          ref: anchorSettings,
          tipsDialogStep: TipsDialogStep.SETTINGS,
        },
      ].map(({ Icon, type, title, Controls, ref, tipsDialogStep: itemTipsDialogStep }) => (
        <Fragment key={type}>
          <CustomIconButton
            title={title}
            clickAwayIgnore
            className={clsx(
              classes.button,
              isTipsDialogActiveWithButton && tipsDialogStep !== itemTipsDialogStep && classes.hidden,
            )}
            ref={ref}
            onClick={(event) => handleClick(type, event)}
          >
            <Icon />
            <RainRadarOffsetChip open={type === 'RAIN_RADAR' && anchor?.type !== 'RAIN_RADAR' && rainRadarPlaying} />
          </CustomIconButton>
          <Controls anchor={anchor?.type === type ? anchor?.anchor : undefined} onClose={handleClose} />
        </Fragment>
      ))}
    </Box>
  );
};
