import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { alpha, ThemeProvider, StyledEngineProvider, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isMobile } from 'react-device-detect';

import { MarkerType } from '../types/MarkerType';
import { theme as globalTheme } from '../setup/theme';
import { MapRainRadarService } from '../services/MapRainRadarService';
import { MapPointsOfInterestService } from '../services/MapPointsOfInterestService';
import { MapRouteService } from '../services/MapRouteService';
import { RouteType } from '../types/RouteType';
import { useMapMarker } from '../hooks/useMapMarker';
import { RoutingRequestService } from '../services/RoutingRequestService';
import { MapBoundService } from '../services/MapBoundService';
import { useStore } from '../setup/global-state';
import { PlaceType } from '../types/PlaceType';
import { ContextPopUp } from '../components/ContextPopUp';
import IconLocationEmpty from '../assets/icons/map/location/empty.svg';
import IconLocationFilled from '../assets/icons/map/location/filled.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  '@global': {
    '.mapboxgl-ctrl.mapboxgl-ctrl-attrib, .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(4),
      },
    },

    '.mapboxgl-ctrl-attrib.mapboxgl-compact': {
      minWidth: theme.spacing(3),
      minHeight: theme.spacing(3),
    },

    '.mapboxgl-ctrl-group:not(:empty)': {
      boxShadow: theme.shadows[1],
    },

    '.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate': {
      '& .mapboxgl-ctrl-icon': {
        backgroundImage: 'none !important',
        backgroundColor: theme.palette.text.primary,
        maskImage: `url(${IconLocationFilled})`,
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
      },

      '&.mapboxgl-ctrl-geolocate-active .mapboxgl-ctrl-icon': {
        backgroundColor: theme.palette.primary.main,
      },

      '&.mapboxgl-ctrl-geolocate-background .mapboxgl-ctrl-icon': {
        maskImage: `url(${IconLocationEmpty})`,
        backgroundColor: theme.palette.primary.main,
      },
    },

    '.mapboxgl-user-location-dot': {
      '&, &::before': {
        backgroundColor: theme.palette.primary.main,
      },

      '&::after': {
        boxShadow: theme.shadows[1],
      },
    },

    '.mapboxgl-user-location-show-heading .mapboxgl-user-location-heading': {
      '&::before, &::after': {
        borderBottomColor: theme.palette.primary.main,
      },
    },

    '.mapboxgl-user-location-accuracy-circle': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },

    '.mapboxgl-ctrl-group, .mapboxgl-ctrl-group button': {
      borderRadius: '50%',
      width: theme.spacing(5),
      height: theme.spacing(5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '.mapboxgl-ctrl-icon': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

export const Map = () => {
  const classes = useStyles();
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();
  const [lng, setLng] = useState(13.030359885025216);
  const [lat, setLat] = useState(47.80978765510332);
  const [zoom, setZoom] = useState(9);
  const contextPopup = useRef<mapboxgl.Popup>();
  const pointsOfInterestPopUp = useRef<mapboxgl.Popup>();
  const originMarker = useRef<mapboxgl.Marker>();
  const destinationMarker = useRef<mapboxgl.Marker>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const mapVisible = useStore((state) => state.mapVisible);
  const origin = useStore((state) => state.places[PlaceType.ORIGIN]);
  const destination = useStore((state) => state.places[PlaceType.DESTINATION]);
  const routes = useStore((state) => state.routes);
  const selectedRoute = useStore((state) => state.selectedRoute);
  const rainRadarVisible = useStore((state) => state.rainRadarVisible);
  const rainRadarOffset = useStore((state) => state.rainRadarOffset);
  const rainRadarPlaying = useStore((state) => state.rainRadarPlaying);
  const pointsOfInterestVisible = useStore((state) => state.pointsOfInterestVisible);
  const pageDividerOpen = useStore((state) => state.pageDividerOpen);
  const updateRoutes = useStore((state) => state.updateRoutes);
  const updateRainRadarOffset = useStore((state) => state.setRainRadarOffset);
  const setPageDividerOpen = useStore((state) => state.setPageDividerOpen);
  const setMapCenter = useStore((state) => state.setMapCenter);
  const intervalIdRef = useRef<NodeJS.Timeout>();

  const updateMarker = useMapMarker({
    map,
    originMarker,
    destinationMarker,
  });

  const openContextMenu = (event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & mapboxgl.EventData) => {
    if (map.current) {
      if (!contextPopup.current) {
        contextPopup.current = new mapboxgl.Popup({
          closeOnClick: true,
          closeOnMove: true,
          closeButton: false,
        });

        const popupContent = document.createElement('div');
        ReactDOM.render(
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={globalTheme}>
              <ContextPopUp
                onSetMarkerClick={(markerType) => {
                  if (contextPopup.current) {
                    updateMarker(contextPopup.current.getLngLat(), markerType, true);
                    contextPopup.current.remove();
                  }
                }}
              />
            </ThemeProvider>
          </StyledEngineProvider>,
          popupContent,
        );
        contextPopup.current.setDOMContent(popupContent);
      }

      contextPopup.current.setLngLat(event.lngLat).addTo(map.current);

      setLat(event.lngLat.lat);
      setLng(event.lngLat.lng);
      setZoom(map.current.getZoom());
    }
  };

  useEffect(() => {
    // Initialize map only once
    if (!mapContainer.current || map.current || !mapVisible) {
      return;
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/trafficon/ckt2mzei625ic18nzjxrv5u76',
      center: [lng, lat],
      zoom,
      touchPitch: false,
      pitchWithRotate: false,
      dragRotate: false,
      locale: {
        'GeolocateControl.FindMyLocation': 'Aktuelle Position anzeigen',
        'GeolocateControl.LocationNotAvailable': 'Aktuelle Position nicht verfügbar',
      },
    });

    map.current.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      }),
    );

    map.current.touchZoomRotate.disableRotation();

    if (isMobile) {
      map.current.on('click', (event) => {
        setTimeout(() => {
          const features = map.current?.queryRenderedFeatures(event.point, {
            layers: ['points-of-interest-unclustered-points'],
          });
          if (features?.length === 0) openContextMenu(event);
        }, 250);
      });
    } else {
      map.current.on('contextmenu', openContextMenu);
    }

    if (matches) {
      map.current.on('touchend', () => {
        setPageDividerOpen(false);
      });
      map.current.on('mouseup', () => {
        setPageDividerOpen(false);
      });
    }

    MapPointsOfInterestService.loadImages(map.current);

    map.current.on('load', () => {
      if (map.current) {
        MapRainRadarService.create(map.current, rainRadarVisible, rainRadarOffset);
        MapRouteService.create(map.current, routes, selectedRoute);
        MapPointsOfInterestService.create(map.current, pointsOfInterestVisible, pointsOfInterestPopUp);
      }
    });

    map.current.on('move', () => {
      if (map.current?.getCenter()) setMapCenter(map.current?.getCenter());
    });
  });

  useEffect(() => {
    setTimeout(() => {
      if (map.current) {
        const oldNorthEast = map.current.getBounds().getNorthEast();
        map.current.resize();

        const newNorthEast = map.current.getBounds().getNorthEast();
        const center = map.current.getCenter();
        const shiftVector = { x: newNorthEast.lng - oldNorthEast.lng, y: newNorthEast.lat - oldNorthEast.lat };
        const shiftedCenter = new mapboxgl.LngLat(center.lng - shiftVector.x, center.lat - shiftVector.y);
        map.current.setCenter(shiftedCenter);

        map.current.easeTo({ center, duration: 500 });
      }
    });
  }, [pageDividerOpen]);

  useEffect(() => {
    updateMarker(origin, MarkerType.ORIGIN);
  }, [origin, updateMarker]);

  useEffect(() => {
    updateMarker(destination, MarkerType.DESTINATION);
  }, [destination, updateMarker]);

  useEffect(() => {
    updateRoutes({ [RouteType.RECOMMENDED]: undefined, [RouteType.FASTEST]: undefined });

    if (origin && destination) {
      (async () => updateRoutes(await RoutingRequestService.requestRoute(origin, destination)))();
    }
  }, [updateRoutes, destination, origin]);

  useEffect(() => {
    if (map.current) {
      MapRouteService.update(map.current, routes);
    }
  }, [routes]);
  useEffect(() => {
    if (map.current) {
      MapRouteService.updateSelected(map.current, selectedRoute);
    }
  }, [selectedRoute]);

  useEffect(() => {
    if (map.current) {
      MapBoundService.fit(map.current, origin, destination, routes);
    }
  }, [origin, destination, routes]);

  useEffect(() => {
    if (map.current) {
      MapRainRadarService.update(map.current, rainRadarVisible, rainRadarOffset);
    }
  }, [rainRadarVisible, rainRadarOffset]);

  useEffect(() => {
    if (rainRadarPlaying) {
      intervalIdRef.current = setInterval(() => {
        if (rainRadarOffset <= 115) {
          updateRainRadarOffset(rainRadarOffset + 5);
        }

        if (rainRadarOffset >= 120) {
          updateRainRadarOffset(-120);
        }
      }, 240);
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [rainRadarPlaying, rainRadarOffset, updateRainRadarOffset]);

  useEffect(() => {
    if (map.current) {
      MapPointsOfInterestService.update(map.current, pointsOfInterestVisible);

      if (!pointsOfInterestVisible && pointsOfInterestPopUp.current) {
        pointsOfInterestPopUp.current.remove();
      }
    }
  }, [pointsOfInterestVisible]);

  return <div ref={mapContainer} className={classes.root} />;
};
