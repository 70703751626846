/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageStructure } from './ImageStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
export type InfoLinkStructure = {
    image?: ImageStructure;
    label?: NaturalLanguageStringStructure;
    linkContent?: InfoLinkStructure.linkContent;
    uri?: string;
};
export namespace InfoLinkStructure {
    export enum linkContent {
        TIMETABLE = 'TIMETABLE',
        RELATED_SITE = 'RELATED_SITE',
        DETAILS = 'DETAILS',
        ADVICE = 'ADVICE',
        OTHER = 'OTHER',
    }
}

