/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionDataStructure } from './ActionDataStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
export type PublishToTvActionStructure = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: PublishToTvActionStructure.actionStatus;
    ceefax?: boolean;
    description?: NaturalLanguageStringStructure;
    teletext?: boolean;
};
export namespace PublishToTvActionStructure {
    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }
}

