import mapboxgl from 'mapbox-gl';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { GlobalState } from '../types/GlobalState';
import { PlaceType } from '../types/PlaceType';
import { RouteType } from '../types/RouteType';
import { TipsDialogStep } from '../types/TipsDialogStep';

export const useStore = create<GlobalState>()(
  persist(
    (set, get) => ({
      pageDividerOpen: true,

      places: {
        [PlaceType.ORIGIN]: undefined,
        [PlaceType.DESTINATION]: undefined,
      },

      routes: { [RouteType.RECOMMENDED]: undefined, [RouteType.FASTEST]: undefined },
      selectedRoute: {
        active: RouteType.RECOMMENDED,
        hover: undefined,
      },

      rainRadarVisible: false,
      rainRadarOffset: 0,
      rainRadarPlaying: false,

      pointsOfInterestVisible: true,

      introductionDialogVisible: true,
      tipsDialogVisible: true,
      tipsDialogStep: TipsDialogStep.DISABLED,

      mapCenter: new mapboxgl.LngLat(13.040522, 47.806506),
      mapVisible: false,

      speed: 15,

      setPageDividerOpen: (pageDividerOpen) => set({ pageDividerOpen }),

      updatePlace: ({ placeType, place }) =>
        set(({ places }) => ({
          places: {
            ...places,
            [placeType]: place,
          },
        })),

      updateRoutes: (routes) =>
        set({
          routes,
          selectedRoute: {
            ...get().selectedRoute,
            active: RouteType.RECOMMENDED,
          },
        }),

      updateSelectedRoute: (selectedRoute) =>
        set({
          selectedRoute: {
            ...get().selectedRoute,
            ...selectedRoute,
          },
        }),

      resetRouting: () => {
        get().updatePlace({ placeType: PlaceType.ORIGIN, place: undefined });
        get().updatePlace({ placeType: PlaceType.DESTINATION, place: undefined });
        get().updateRoutes({ [RouteType.RECOMMENDED]: undefined, [RouteType.FASTEST]: undefined });
      },

      toggleRainRadarVisible: () => set({ rainRadarVisible: !get().rainRadarVisible }),
      toggleRainRadarPlaying: () => set({ rainRadarPlaying: !get().rainRadarPlaying }),
      setRainRadarOffset: (rainRadarOffset) => set({ rainRadarOffset }),
      setRainRadarPlaying: (rainRadarPlaying) => set({ rainRadarPlaying }),

      togglePointsOfInterestVisible: () => set({ pointsOfInterestVisible: !get().pointsOfInterestVisible }),

      setMapCenter: (mapCenter) => set({ mapCenter }),
      setMapVisible: (mapVisible) => set({ mapVisible }),

      setSpeed: (speed) => set({ speed }),

      setIntroductionDialogVisible: (introductionDialogVisible) => set({ introductionDialogVisible }),
      setTipsDialogVisible: (tipsDialogVisible) => set({ tipsDialogVisible }),
      setTipsDialogStep: (tipsDialogStep) => set({ tipsDialogStep }),
    }),
    {
      name: 'radlkarte.info',
      version: 1,
      migrate: (persistedState, version) => {
        if (version < 1) {
          return {} as GlobalState;
        }

        return persistedState as GlobalState;
      },

      serialize: (state) =>
        JSON.stringify({
          ...state,
          state: {
            ...state.state,
            selectedRoute: { active: RouteType.RECOMMENDED, hover: undefined },
            rainRadarOffset: 0,
            rainRadarPlaying: false,
          },
        }),
    },
  ),
);
