import { Place } from '../types/Place';
import { IndividualTransportOptionsStructure } from './api-routing/models/IndividualTransportOptionsStructure';
import { Trias } from './api-routing/models/Trias';
import { IndividualTripParamStructure } from './api-routing/models/IndividualTripParamStructure';

export class TriasService {
  public static createRouteRequest(
    origin: Place,
    destination: Place,
    algorithmType: IndividualTripParamStructure.algorithmType,
  ): Trias {
    return {
      serviceRequest: {
        requestTimestamp: new Date().toISOString(),
        requestorRef: { value: 'radlkarte40:webclient' },
        dataVersion: 'TriasExtensionTrafficon:2.7',
        requestPayload: {
          individualRouteRequest: {
            origin: {
              locationRef: {
                geoPosition: { latitude: origin.lat, longitude: origin.lng },
                locationName: { text: origin.name.full, language: 'de' },
              },
              depArrTime: new Date().toISOString(),
            },
            destination: {
              locationRef: {
                geoPosition: { latitude: destination.lat, longitude: destination.lng },
                locationName: { text: destination.name.full, language: 'de' },
              },
            },
            mode: [{ mode: IndividualTransportOptionsStructure.mode.CYCLE, maxDistance: 1000000 }],
            params: {
              algorithmType,
              banMotorways: false,
              banTollRoads: false,
              banFerries: true,
              allowUnpavedRoads: true,
              includeTrackSections: true,
              includeLegProjection: true,
            },
          },
        },
      },
    };
  }

  public static getRouteFromTrias(trias?: Trias) {
    return trias?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult?.[0].route;
  }
}
