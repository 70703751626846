import React from 'react';
import { Box, ListItem, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { RouteType } from '../types/RouteType';
import { RoutePropertyService } from '../services/RoutePropertyService';
import { ReactComponent as ArrowUphillIcon } from '../assets/icons/forms/arrow-uphill.svg';
import { ReactComponent as ArrowDownhillIcon } from '../assets/icons/forms/arrow-downhill.svg';
import { RouteStructure } from '../services/api-routing';
import { TipsDialogStep } from '../types/TipsDialogStep';
import { useStore } from '../setup/global-state';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  listItemActive: {
    borderLeftColor: theme.palette.primary.main,
  },
  listItemTextPrimary: {
    fontWeight: 'bold',
  },
  listItemTextSecondary: {
    color: '#555555',
  },
  listItemRecommended: {
    [theme.breakpoints.up('md')]: {
      zIndex: 1500,
      backgroundColor: theme.palette.common.white,
    },
  },
  arrowIcon: {
    marginRight: theme.spacing(1),
    width: 10,
    height: 10,
  },
  infos: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  duration: {
    minWidth: 90,
  },
}));

type RouteSelectItemProps = {
  routeType: RouteType;
  route: RouteStructure;
};

export const RouteSelectItem = ({ routeType, route }: RouteSelectItemProps) => {
  const classes = useStyles();

  const selectedRoute = useStore((state) => state.selectedRoute);
  const tipsDialogStep = useStore((state) => state.tipsDialogStep);
  const speed = useStore((state) => state.speed);
  const updateSelectedRoute = useStore((state) => state.updateSelectedRoute);

  return (
    <ListItem
      key={routeType}
      button
      className={clsx(
        classes.listItem,
        selectedRoute.active === routeType && classes.listItemActive,
        routeType === RouteType.RECOMMENDED && tipsDialogStep === TipsDialogStep.ROUTES && classes.listItemRecommended,
      )}
      onClick={() => updateSelectedRoute({ active: routeType })}
      onMouseEnter={() => updateSelectedRoute({ hover: routeType })}
      onMouseLeave={() => updateSelectedRoute({ hover: undefined })}
    >
      <ListItemText
        classes={{
          primary: classes.listItemTextPrimary,
          secondary: classes.listItemTextSecondary,
        }}
        secondaryTypographyProps={{ component: 'div' }}
        primary={{ [RouteType.RECOMMENDED]: 'Empfohlene Route', [RouteType.FASTEST]: 'Schnellste Route' }[routeType]}
        secondary={
          <Box className={classes.infos}>
            <Box className={classes.duration}>
              {RoutePropertyService.getReadableDuration(
                route.distance,
                speed,
                RoutePropertyService.getSpeedFactor(route.routeLeg?.[0].extension?.any),
              )}
            </Box>
            <Box>{RoutePropertyService.getReadableDistance(route.distance)}</Box>
            <Box>
              <ArrowUphillIcon className={classes.arrowIcon} />
              {RoutePropertyService.getReadableElevationGain(route.routeLeg?.[0].extension?.any)}
            </Box>
            <Box>
              <ArrowDownhillIcon className={classes.arrowIcon} />
              {RoutePropertyService.getReadableElevationLoss(route.routeLeg?.[0].extension?.any)}
            </Box>
          </Box>
        }
      />
    </ListItem>
  );
};
