import mapboxgl from 'mapbox-gl';
import React, { MutableRefObject, useCallback } from 'react';
import ReactDOM from 'react-dom';

import { Place } from '../types/Place';
import { MarkerType } from '../types/MarkerType';
import { MapHelperService } from '../services/MapHelperService';
import { GeocoderFeatureService } from '../services/GeocoderFeatureService';
import { PlaceType } from '../types/PlaceType';
import { GeocoderService } from '../services/api-geocoding';
import { MarkerImage } from '../components/MarkerImage';
import { useStore } from '../setup/global-state';

export const useMapMarker = ({
  map,
  originMarker,
  destinationMarker,
}: {
  map: MutableRefObject<mapboxgl.Map | undefined>;
  originMarker: MutableRefObject<mapboxgl.Marker | undefined>;
  destinationMarker: MutableRefObject<mapboxgl.Marker | undefined>;
}) => {
  const updatePlace = useStore((state) => state.updatePlace);

  const reverseGeocode = useCallback(
    async (markerType: MarkerType, coordinates: Pick<Place, 'lat' | 'lng'>) => {
      const response = await GeocoderService.reverse({
        lat: coordinates.lat,
        lon: coordinates.lng,
      });

      const placeType = { [MarkerType.ORIGIN]: PlaceType.ORIGIN, [MarkerType.DESTINATION]: PlaceType.DESTINATION }[
        markerType
      ];

      const full = `${coordinates.lat.toFixed(6)}, ${coordinates.lng.toFixed(6)}`;

      updatePlace({
        placeType,
        place: {
          name: response.features?.length
            ? GeocoderFeatureService.getNameFromFeature(response.features?.[0])
            : { full, firstLine: full },
          ...coordinates,
        },
      });
    },
    [updatePlace],
  );

  const updateMarker = useCallback(
    async (
      coordinates: Pick<Place, 'lat' | 'lng'> | undefined,
      markerType: MarkerType,
      withReverseGeocode: boolean = false,
    ) => {
      const marker = { [MarkerType.ORIGIN]: originMarker, [MarkerType.DESTINATION]: destinationMarker }[markerType];

      if (coordinates) {
        if (!marker.current || !MapHelperService.compareCoordinatesAndMarker(coordinates, marker.current)) {
          if (map.current) {
            if (!marker.current) {
              const markerElement = document.createElement('div');
              ReactDOM.render(<MarkerImage markerType={markerType} />, markerElement);

              marker.current = new mapboxgl.Marker({ draggable: true, element: markerElement, anchor: 'bottom' });
              marker.current.on('dragend', () => {
                if (marker.current) {
                  reverseGeocode(markerType, marker.current.getLngLat());
                }
              });
            }

            marker.current.setLngLat(coordinates);
            marker.current.addTo(map.current);

            if (withReverseGeocode) {
              reverseGeocode(markerType, marker.current?.getLngLat());
            }
          }
        }
      } else {
        marker.current?.remove();
        marker.current = undefined;
      }
    },
    [destinationMarker, map, originMarker, reverseGeocode],
  );

  return updateMarker;
};
