/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryRefStructure } from './CountryRefStructure';
import type { EntryQualifierStructure } from './EntryQualifierStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { ParticipantRefStructure } from './ParticipantRefStructure';
import type { SituationVersion } from './SituationVersion';
export type RelatedSituationStructure = {
    countryRef?: CountryRefStructure;
    creationTime?: string;
    extensions?: ExtensionsStructure;
    externalReference?: string;
    participantRef?: ParticipantRefStructure;
    relatedAs?: RelatedSituationStructure.relatedAs;
    situationNumber?: EntryQualifierStructure;
    updateCountryRef?: CountryRefStructure;
    updateParticipantRef?: ParticipantRefStructure;
    version?: SituationVersion;
};
export namespace RelatedSituationStructure {
    export enum relatedAs {
        CAUSE = 'CAUSE',
        EFFECT = 'EFFECT',
        UPDATE = 'UPDATE',
        SUPERCEDES = 'SUPERCEDES',
        SUPERCEDED_BY = 'SUPERCEDED_BY',
        ASSOCIATED = 'ASSOCIATED',
    }
}

