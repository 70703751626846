import React from 'react';
import { ListItemIcon, MenuItem, MenuList } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ReactComponent as MarkerOrigin } from '../assets/icons/forms/marker-origin.svg';
import { ReactComponent as MarkerDestination } from '../assets/icons/forms/marker-destination.svg';
import { MarkerType } from '../types/MarkerType';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[1],
  },
  listIcon: {
    color: theme.palette.primary.main,
    minWidth: 'initial',
    marginRight: theme.spacing(2),

    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.secondary.main,
    },
  },
}));

type ContextPopUpProps = {
  onSetMarkerClick: (markerType: MarkerType) => void;
};

export const ContextPopUp = ({ onSetMarkerClick }: ContextPopUpProps) => {
  const classes = useStyles();

  return (
    <MenuList className={classes.root}>
      <MenuItem onClick={() => onSetMarkerClick(MarkerType.ORIGIN)}>
        <ListItemIcon className={classes.listIcon}>
          <MarkerOrigin />
        </ListItemIcon>
        Start setzen
      </MenuItem>
      <MenuItem onClick={() => onSetMarkerClick(MarkerType.DESTINATION)}>
        <ListItemIcon className={classes.listIcon}>
          <MarkerDestination />
        </ListItemIcon>
        Ziel setzen
      </MenuItem>
    </MenuList>
  );
};
