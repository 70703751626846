/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtensionsStructure } from './ExtensionsStructure';
export type IndividualTripParamStructure = {
    algorithmType?: IndividualTripParamStructure.algorithmType;
    allowUnpavedRoads?: boolean;
    banBridges?: boolean;
    banFerries?: boolean;
    banMotorways?: boolean;
    banTollRoads?: boolean;
    banTunnels?: boolean;
    extension?: ExtensionsStructure;
    ignoreRealtimeData?: boolean;
    immediateTripStart?: boolean;
    includeAccessibility?: boolean;
    includeLegProjection?: boolean;
    includeTrackSections?: boolean;
    includeTurnDescription?: boolean;
    noElevator?: boolean;
    noEscalator?: boolean;
    noRamp?: boolean;
    noSingleStep?: boolean;
    noStairs?: boolean;
    numberOfResults?: number;
    numberOfResultsAfter?: number;
    numberOfResultsBefore?: number;
};
export namespace IndividualTripParamStructure {
    export enum algorithmType {
        FASTEST = 'FASTEST',
        SHORTEST = 'SHORTEST',
        BEAUTIFUL = 'BEAUTIFUL',
        OPTIMAL = 'OPTIMAL',
        ECONOMIC = 'ECONOMIC',
    }
}

