import React, { ReactNode } from 'react';
import { Box, ClickAwayListener, Fade, Paper, Popper, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  popper: {
    [theme.breakpoints.up('md')]: {
      zIndex: 2000,
    },
  },
  scrollWrapper: {
    [theme.breakpoints.down('md')]: {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        bottom: theme.spacing(2.5),
        left: 0,
        height: theme.spacing(2),
        borderBottom: `${theme.spacing(1)} solid ${theme.palette.common.white}`,
        backgroundImage:
          'linear-gradient(to top, rgba(255, 255, 255, 0.738) 19%, rgba(255, 255, 255, 0.541) 34%, rgba(255, 255, 255, 0.382) 47%, rgba(255, 255, 255, 0.278) 56.5%, rgba(255, 255, 255, 0.194) 65%, rgba(255, 255, 255, 0.126) 73%, rgba(255, 255, 255, 0.075) 80.2%, rgba(255, 255, 255, 0.042) 86.1%, rgba(255, 255, 255, 0.021) 91%, rgba(255, 255, 255, 0.008) 95.2%, rgba(255, 255, 255, 0.002) 98.2%, transparent 100%)',
        pointerEvents: 'none',
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: 0,
    position: 'relative',
    marginBottom: theme.spacing(2.5),
    maxWidth: `calc(100vw - ${theme.spacing(4)})`,
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      width: `calc(100vw - ${theme.spacing(4)})`,
      maxWidth: theme.spacing(50),
      maxHeight: ({ mobileMaxHeight }: { mobileMaxHeight: number | string }) => mobileMaxHeight,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(2.5),
      minWidth: theme.spacing(50),
    },
  },
  triangle: {
    position: 'relative',
    top: -1,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
    borderColor: `${theme.palette.common.white} transparent transparent transparent`,
    marginBottom: theme.spacing(0.5),

    [theme.breakpoints.up('md')]: {
      top: 0,
      borderWidth: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
      borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
      marginLeft: theme.spacing(0.5),
      marginBottom: 0,
    },
  },
}));

type ControlsPopperProps = {
  anchor?: HTMLButtonElement;
  children: ReactNode;
  onClose: () => void;
  mobileMaxHeight?: number | string;
};

export const ControlsPopper = ({ anchor, onClose, children, mobileMaxHeight = 'initial' }: ControlsPopperProps) => {
  const classes = useStyles({ mobileMaxHeight });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Popper
        className={classes.popper}
        open={!!anchor}
        anchorEl={anchor}
        placement={matches ? 'right' : 'top'}
        transition
        modifiers={[
          {
            name: 'preventOverflow',
            options: { padding: 16 },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={(event) => {
              if (!event.target || !(event.target as HTMLElement).closest('[data-click-away-ignore]')) {
                onClose();
              }
            }}
          >
            <Fade {...TransitionProps}>
              <Box className={classes.scrollWrapper}>
                <Paper className={classes.paper} elevation={1}>
                  {children}
                </Paper>
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <Popper
        className={classes.popper}
        open={!!anchor}
        anchorEl={anchor}
        placement={matches ? 'right' : 'top'}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box className={classes.triangle} />
          </Fade>
        )}
      </Popper>
    </>
  );
};
