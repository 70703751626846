/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionDataStructure } from './ActionDataStructure';
import type { BeforeNotices } from './BeforeNotices';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
export type NotifyUserActionStructure = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: NotifyUserActionStructure.actionStatus;
    beforeNotices?: BeforeNotices;
    clearNotice?: boolean;
    description?: NaturalLanguageStringStructure;
    userName?: string;
    userRef?: string;
    workgroupRef?: string;
};
export namespace NotifyUserActionStructure {
    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }
}

