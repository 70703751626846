import React from 'react';

import { SettingsControls } from './SettingsControls';
import { ControlsPopper } from './ControlsPopper';

type SettingsPopperProps = {
  anchor?: HTMLButtonElement;
  onClose: () => void;
};

export const SettingsPopper = ({ anchor, onClose }: SettingsPopperProps) => (
  <ControlsPopper anchor={anchor} onClose={onClose}>
    <SettingsControls onClose={onClose} />
  </ControlsPopper>
);
