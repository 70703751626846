/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessibilityAssessmentStructure } from './AccessibilityAssessmentStructure';
import type { AffectedComponents } from './AffectedComponents';
import type { AffectedNavigationPaths } from './AffectedNavigationPaths';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { StopPlaceRefStructure } from './StopPlaceRefStructure';
export type AffectedStopPlaceStructure = {
    accessibilityAssessment?: AccessibilityAssessmentStructure;
    affectedComponents?: AffectedComponents;
    affectedNavigationPaths?: AffectedNavigationPaths;
    extensions?: ExtensionsStructure;
    placeName?: NaturalLanguageStringStructure;
    stopPlaceRef?: StopPlaceRefStructure;
    stopPlaceType?: AffectedStopPlaceStructure.stopPlaceType;
};
export namespace AffectedStopPlaceStructure {
    export enum stopPlaceType {
        AIRPORT = 'AIRPORT',
        RAIL_STATION = 'RAIL_STATION',
        METRO_STATION = 'METRO_STATION',
        COACH_STATION = 'COACH_STATION',
        BUS_STATION = 'BUS_STATION',
        HARBOUR_PORT = 'HARBOUR_PORT',
        FERRYT_PORT = 'FERRYT_PORT',
        FERRY_STOP = 'FERRY_STOP',
        ON_STREET_BUS = 'ON_STREET_BUS',
        ON_STREET_TRAM = 'ON_STREET_TRAM',
        SKI_LIFT = 'SKI_LIFT',
        OTHER = 'OTHER',
    }
}

