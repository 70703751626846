import { GeoJSON } from 'geojson';

import { RouteStructure } from './api-routing';

export class RoutePropertyService {
  public static getReadableDuration(distance: number | undefined, speed: number, speedFactor: number) {
    if (!distance) return '';
    const totalSeconds = Math.floor((distance / ((speed * 1000) / 60 / 60)) * (speedFactor / 100));
    const seconds = totalSeconds % 60;
    const addMin = Math.round(seconds / 60);
    let hours = Math.floor(totalSeconds / 3600) % 1000;
    let minutes = (Math.floor(totalSeconds / 60) % 60) + addMin;
    if (minutes > 59) {
      minutes = 0;
      hours += 1;
    }
    let result = '';
    if (hours > 0) result += `${hours} h `;
    if (minutes) result += `${minutes} min`;
    if (hours === 0 && minutes <= 1) result = '< 1 min';

    return result;
  }

  public static getSpeedFactor(extension: any) {
    if (extension.name === '{http://www.vdv.de/trias/extension/trafficon}RouteInformation') {
      const speedFactor = extension.value.speedFactor as number;

      return speedFactor === 0 ? 100 : speedFactor;
    }

    return 100;
  }

  public static getReadableDistance(distance: number | undefined) {
    if (distance === undefined) return '';
    if (distance < 1000) return `${distance} m`;

    return `${(distance / 1000).toFixed(1).replace('.', ',')} km`;
  }

  public static getReadableElevationGain(extension: any) {
    if (extension.name === '{http://www.vdv.de/trias/extension/trafficon}RouteInformation') {
      const elevationGain = extension.value.cumulativeElevationGain as number;

      return `${elevationGain.toFixed(0)} hm`;
    }

    return '';
  }

  public static getReadableElevationLoss(extension: any) {
    if (extension.name === '{http://www.vdv.de/trias/extension/trafficon}RouteInformation') {
      const elevationLoss = extension.value.cumulativeElevationLoss as number;

      return `${elevationLoss.toFixed(0)} hm`;
    }

    return '';
  }

  public static getGeoJsonFromRoute(route: RouteStructure | undefined) {
    const geojson: GeoJSON.FeatureCollection<GeoJSON.LineString> = {
      type: 'FeatureCollection',
      features: [],
    };

    if (route === undefined) return geojson;

    route.routeLeg?.forEach((leg) => {
      const lineString: GeoJSON.LineString = {
        type: 'LineString',
        coordinates: [],
      };

      leg.legTrack?.trackSection?.forEach((section) => {
        section.projection?.position?.forEach((pos) => {
          lineString.coordinates.push([pos.longitude as number, pos.latitude as number]);
        });
      });

      const feature: GeoJSON.Feature<GeoJSON.LineString> = {
        type: 'Feature',
        geometry: lineString,
        properties: {},
      };

      geojson.features.push(feature);
    });

    return geojson;
  }
}
