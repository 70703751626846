import React from 'react';

import { useStore } from '../setup/global-state';
import { CustomSwitch } from './CustomSwitch';

export const LayersControls = () => {
  const pointsOfInterestVisible = useStore((state) => state.pointsOfInterestVisible);
  const togglePointsOfInterestVisible = useStore((state) => state.togglePointsOfInterestVisible);

  return (
    <CustomSwitch
      name="points-of-interest-visible"
      label="Radinfrastruktur einblenden"
      checked={pointsOfInterestVisible}
      onChange={() => togglePointsOfInterestVisible()}
    />
  );
};
