import React from 'react';

import { ControlsPopper } from './ControlsPopper';
import { RainRadarControls } from './RainRadarControls';

type RainRadarPopperProps = { anchor?: HTMLButtonElement; onClose: () => void };

export const RainRadarPopper = ({ anchor, onClose }: RainRadarPopperProps) => (
  <ControlsPopper anchor={anchor} onClose={onClose} mobileMaxHeight={164}>
    <RainRadarControls />
  </ControlsPopper>
);
