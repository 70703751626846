import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useStore } from '../setup/global-state';
import { TipsDialogStep } from '../types/TipsDialogStep';
import { HeaderNavigation } from './HeaderNavigation';
import { ControlButtons } from './ControlButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column-reverse',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  map: {
    flex: '1 1 0',
  },
  content: {
    position: 'relative',
    minHeight: theme.spacing(5.5),
    boxShadow: theme.shadows[1],

    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateAreas: '"main" "footer"',
      gridTemplateRows: `1fr ${theme.spacing(16)}`,
      minHeight: 'initial',
      minWidth: theme.spacing(5.5),
    },
  },
  contentZIndex: {
    zIndex: 1,
  },
  contentOpen: {
    [theme.breakpoints.up('md')]: {
      flex: '1 1 0',
    },
  },
}));

type PageDividerProps = {
  children: ReactNode;
  map: ReactNode;
};

export const PageDivider = ({ children, map }: PageDividerProps) => {
  const pageDividerOpen = useStore((state) => state.pageDividerOpen);
  const tipsDialogVisible = useStore((state) => state.tipsDialogVisible);
  const tipsDialogStep = useStore((state) => state.tipsDialogStep);

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <HeaderNavigation />

      <Box
        className={clsx(
          classes.content,
          !(tipsDialogVisible && tipsDialogStep > TipsDialogStep.DISABLED) && classes.contentZIndex,
          pageDividerOpen && classes.contentOpen,
        )}
      >
        {pageDividerOpen && children}

        <ControlButtons />
      </Box>

      <Box className={classes.map}>{map}</Box>
    </Box>
  );
};
