/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocalityRefStructure } from './LocalityRefStructure';
import type { OperatorFilterStructure } from './OperatorFilterStructure';
import type { PointOfInterestFilterStructure } from './PointOfInterestFilterStructure';
import type { PtModeFilterStructure } from './PtModeFilterStructure';
export type LocationParamStructure = {
    continueAt?: number;
    includePtModes?: boolean;
    language?: string;
    localityRef?: Array<LocalityRefStructure>;
    numberOfResults?: number;
    operatorFilter?: OperatorFilterStructure;
    pointOfInterestFilter?: PointOfInterestFilterStructure;
    ptModes?: PtModeFilterStructure;
    type?: Array<'STOP' | 'ADDRESS' | 'POI' | 'COORD' | 'LOCALITY'>;
    usage?: LocationParamStructure.usage;
};
export namespace LocationParamStructure {
    export enum usage {
        ORIGIN = 'ORIGIN',
        VIA = 'VIA',
        DESTINATION = 'DESTINATION',
    }
}

