import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
}));

type ControlsSectionProps = { children: ReactNode };

export const ControlsSection = ({ children }: ControlsSectionProps) => {
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};
