import React from 'react';
import { Typography, Link as MuiLink, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import { useStore } from '../setup/global-state';
import introductionImage from '../assets/images/introduction.png';
import { CustomSwitch } from './CustomSwitch';
import { CustomDialog } from './CustomDialog';

const useStyles = makeStyles((theme) => ({
  privacyText: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.spacing(60),
  },
  link: {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
  },
  switchTips: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

export const IntroductionDialog = () => {
  const introductionDialogVisible = useStore((state) => state.introductionDialogVisible);
  const tipsDialogVisible = useStore((state) => state.tipsDialogVisible);
  const setIntroductionDialogVisible = useStore((state) => state.setIntroductionDialogVisible);
  const setTipsDialogVisible = useStore((state) => state.setTipsDialogVisible);

  const setMapVisible = useStore((state) => state.setMapVisible);

  const classes = useStyles();

  const handleClose = (event?: HTMLButtonElement | undefined, reason?: string) => {
    if (reason !== 'backdropClick') {
      setIntroductionDialogVisible(false);

      setMapVisible(true);
    }
  };

  return (
    <CustomDialog
      open={introductionDialogVisible}
      onClose={handleClose}
      onNext={handleClose}
      large
      image={introductionImage}
      imageAlt="FahrradfahrerInnen vor der Stadt Salzburg"
      title="Griaß di!"
      subTitle="Zum ersten Mal da?"
      content="Die Salzburger Radlkarte hat die besten Empfehlungen für deinen Rad Alltag! Kein Wunder - sie wird von Stadt und Land Salzburg betrieben - die kennen sich natürlich aus wie kein Anderer."
      beforeActionsContent={
        <Typography align="center" className={classes.privacyText}>
          Für die Kartendarstellung nutzen wir Mapbox. Mit Klick auf &quot;Weiter&quot; stimmen Sie der Nutzung dieses
          Kartendienstes für die Radlkarte und der Übertragung Ihrer IP-Adresse an einen Server von Mapbox in den USA
          zu. Weitere Informationen finden Sie unter{' '}
          <MuiLink component={Link} className={classes.link} to="/datenschutz">
            Datenschutz
          </MuiLink>{' '}
          und im{' '}
          <MuiLink
            className={classes.link}
            href="https://www.stadt-salzburg.at/impressum/"
            target="_blank"
            referrerPolicy="no-referrer"
          >
            Impressum
          </MuiLink>
          .
        </Typography>
      }
      nextLabel="Weiter"
      disableClose
      afterActionsContent={
        <Box className={classes.switchTips}>
          <CustomSwitch
            name="dialogs-visible"
            label="Tipps anzeigen"
            checked={tipsDialogVisible}
            onChange={() => setTipsDialogVisible(!tipsDialogVisible)}
            border
          />
        </Box>
      }
    />
  );
};
