/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionDataStructure } from './ActionDataStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
export type PublishToMobileActionStructure = {
    actionData?: Array<ActionDataStructure>;
    actionStatus?: PublishToMobileActionStructure.actionStatus;
    description?: NaturalLanguageStringStructure;
    homePage?: boolean;
    incidents?: boolean;
};
export namespace PublishToMobileActionStructure {
    export enum actionStatus {
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSED = 'CLOSED',
    }
}

