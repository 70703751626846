import React from 'react';
import { makeStyles } from '@mui/styles';

import { MarkerType } from '../types/MarkerType';
import originMarker from '../assets/icons/map/marker-origin.png';
import destinationMarker from '../assets/icons/map/marker-destination.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    width: 72,
    height: 'auto',
  },
}));

type MarkerImageProps = {
  markerType: MarkerType;
};

export const MarkerImage = ({ markerType }: MarkerImageProps) => {
  const classes = useStyles();

  return (
    <img
      src={{ [MarkerType.ORIGIN]: originMarker, [MarkerType.DESTINATION]: destinationMarker }[markerType]}
      alt=""
      className={classes.root}
    />
  );
};
