/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeoPositionStructure } from './GeoPositionStructure';
import type { ProgressBetweenStopsStructure } from './ProgressBetweenStopsStructure';
export type VehiclePositionStructure = {
    bearing?: number;
    geoPosition?: GeoPositionStructure;
    progress?: VehiclePositionStructure.progress;
    progressBetweenStops?: ProgressBetweenStopsStructure;
};
export namespace VehiclePositionStructure {
    export enum progress {
        NOT_YET_OPERATED = 'NOT_YET_OPERATED',
        OPERATION_FINISHED = 'OPERATION_FINISHED',
        AT_STOP = 'AT_STOP',
        BETWEEN_STOPS = 'BETWEEN_STOPS',
    }
}

