import { RouteType } from '../types/RouteType';
import { Place } from '../types/Place';
import { TriasService } from './TriasService';
import { IndividualTripParamStructure, RolltApiControllerService, Trias } from './api-routing';

export class RoutingRequestService {
  public static async requestRoute(origin: Place, destination: Place): Promise<Record<RouteType, Trias>> {
    const triasRequestRecommended: Trias = TriasService.createRouteRequest(
      origin,
      destination,
      IndividualTripParamStructure.algorithmType.OPTIMAL,
    );

    const triasRequestFastest: Trias = TriasService.createRouteRequest(
      origin,
      destination,
      IndividualTripParamStructure.algorithmType.FASTEST,
    );

    const [responseRecommended, responseFastest] = await Promise.all([
      RolltApiControllerService.requestRouteTriasUsingPost({
        requestHeader: undefined,
        triasRequest: triasRequestRecommended,
      }),
      RolltApiControllerService.requestRouteTriasUsingPost({
        requestHeader: undefined,
        triasRequest: triasRequestFastest,
      }),
    ]);

    return { [RouteType.RECOMMENDED]: responseRecommended, [RouteType.FASTEST]: responseFastest };
  }
}
