import mapboxgl from 'mapbox-gl';

import { Place } from '../types/Place';
import { RouteType } from '../types/RouteType';
import { Trias } from './api-routing';
import { RoutePropertyService } from './RoutePropertyService';

export class MapBoundService {
  public static fit(
    map: mapboxgl.Map,
    origin: Place | undefined,
    destination: Place | undefined,
    routes: Record<RouteType, Trias | undefined>,
  ) {
    if (origin && destination) {
      const bounds = new mapboxgl.LngLatBounds();

      [RouteType.RECOMMENDED, RouteType.FASTEST].forEach((routeType) => {
        if (routes[routeType]?.serviceDelivery?.status) {
          RoutePropertyService.getGeoJsonFromRoute(
            routes[routeType]?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult?.[0].route,
          ).features[0].geometry.coordinates.forEach((coordinate) => bounds.extend(coordinate as [number, number]));
        }
      });

      [origin, destination].forEach((place) => {
        bounds.extend([place.lng, place.lat]);
      });

      if (!bounds.isEmpty()) {
        try {
          map.fitBounds(bounds, { padding: 80 });
        } catch {
          // Remove try catch when fixed
          // https://github.com/mapbox/mapbox-gl-js/issues/8732
        }
      }
    } else {
      [origin, destination].forEach((place) => {
        if (place && !map.getBounds().contains(place)) {
          map.panTo(place);
        }
      });
    }
  }
}
