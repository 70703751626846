import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ContentPageWrapper } from '../components/ContentPageWrapper';

const useStyles = makeStyles(() => ({
  centered: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  textContent: {
    textAlign: 'center',
  },
}));

export const EndOfProjectPage = () => {
  const classes = useStyles();

  return (
    <ContentPageWrapper containerClassName={classes.centered}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} className={classes.textContent}>
          <Typography gutterBottom variant="h1">
            Pfiat di
          </Typography>
          <Box>
            <Typography>
              Der Betrieb der Radlkarte Salzburg wurde von Stadt und Land Salzburg eingestellt. Wir bedanken uns bei
              allen Nutzerinnen und Nutzern für das Vertrauen und wünschen weiterhin ein erfolgreiches Radeln.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ContentPageWrapper>
  );
};
