/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AllFacilitiesFeatureStructure = {
    accessFacility?: AllFacilitiesFeatureStructure.accessFacility;
    accommodationFacility?: AllFacilitiesFeatureStructure.accommodationFacility;
    assistanceFacility?: AllFacilitiesFeatureStructure.assistanceFacility;
    fareClassFacility?: AllFacilitiesFeatureStructure.fareClassFacility;
    hireFacility?: AllFacilitiesFeatureStructure.hireFacility;
    luggageFacility?: AllFacilitiesFeatureStructure.luggageFacility;
    mobilityFacility?: AllFacilitiesFeatureStructure.mobilityFacility;
    nuisanceFacility?: AllFacilitiesFeatureStructure.nuisanceFacility;
    parkingFacility?: AllFacilitiesFeatureStructure.parkingFacility;
    passengerCommsFacility?: AllFacilitiesFeatureStructure.passengerCommsFacility;
    passengerInformationFacility?: AllFacilitiesFeatureStructure.passengerInformationFacility;
    refreshmentFacility?: AllFacilitiesFeatureStructure.refreshmentFacility;
    reservedSpaceFacility?: AllFacilitiesFeatureStructure.reservedSpaceFacility;
    retailFacility?: AllFacilitiesFeatureStructure.retailFacility;
    sanitaryFacility?: AllFacilitiesFeatureStructure.sanitaryFacility;
    ticketingFacility?: AllFacilitiesFeatureStructure.ticketingFacility;
};
export namespace AllFacilitiesFeatureStructure {
    export enum accessFacility {
        UNKNOWN = 'UNKNOWN',
        LIFT = 'LIFT',
        ESCALATOR = 'ESCALATOR',
        TRAVELATOR = 'TRAVELATOR',
        RAMP = 'RAMP',
        STAIRS = 'STAIRS',
        SHUTTLE = 'SHUTTLE',
        NARROW_ENTRANCE = 'NARROW_ENTRANCE',
        BARRIER = 'BARRIER',
        PALLET_ACCESS_LOW_FLOOR = 'PALLET_ACCESS_LOW_FLOOR',
        VALIDATOR = 'VALIDATOR',
    }
    export enum accommodationFacility {
        UNKNOWN = 'UNKNOWN',
        PTI_23_3 = 'PTI_23_3',
        SLEEPER = 'SLEEPER',
        PTI_23_4 = 'PTI_23_4',
        COUCHETTE = 'COUCHETTE',
        PTI_23_5 = 'PTI_23_5',
        SPECIAL_SEATING = 'SPECIAL_SEATING',
        PTI_23_11 = 'PTI_23_11',
        FREE_SEATING = 'FREE_SEATING',
        PTI_23_12 = 'PTI_23_12',
        RECLINING_SEATS = 'RECLINING_SEATS',
        PTI_23_13 = 'PTI_23_13',
        BABY_COMPARTMENT = 'BABY_COMPARTMENT',
        FAMILY_CARRIAGE = 'FAMILY_CARRIAGE',
    }
    export enum assistanceFacility {
        UNKNOWN = 'UNKNOWN',
        POLICE = 'POLICE',
        FIRST_AID = 'FIRST_AID',
        SOS_POINT = 'SOS_POINT',
        SPECIFIC_ASSISTANCE = 'SPECIFIC_ASSISTANCE',
        UNACCOMPANIED_MINOR_ASSISTANCE = 'UNACCOMPANIED_MINOR_ASSISTANCE',
        BOARDING_ASSISTANCE = 'BOARDING_ASSISTANCE',
    }
    export enum fareClassFacility {
        UNKNOWN = 'UNKNOWN',
        PTI_23_0 = 'PTI_23_0',
        PTI_23_6 = 'PTI_23_6',
        FIRST_CLASS = 'FIRST_CLASS',
        PTI_23_7 = 'PTI_23_7',
        SECOND_CLASS = 'SECOND_CLASS',
        PTI_23_8 = 'PTI_23_8',
        THIRD_CLASS = 'THIRD_CLASS',
        PTI_23_9 = 'PTI_23_9',
        ECONOMY_CLASS = 'ECONOMY_CLASS',
        PTI_23_10 = 'PTI_23_10',
        BUSINESS_CLASS = 'BUSINESS_CLASS',
    }
    export enum hireFacility {
        UNKNOWN = 'UNKNOWN',
        CAR_HIRE = 'CAR_HIRE',
        MOTOR_CYCLE_HIRE = 'MOTOR_CYCLE_HIRE',
        CYCLE_HIRE = 'CYCLE_HIRE',
        TAXI = 'TAXI',
        RECREATION_DEVICE_HIRE = 'RECREATION_DEVICE_HIRE',
    }
    export enum luggageFacility {
        UNKNOWN = 'UNKNOWN',
        PTI_23_17 = 'PTI_23_17',
        BIKE_CARRIAGE = 'BIKE_CARRIAGE',
        BAGGAGE_STORAGE = 'BAGGAGE_STORAGE',
        LEFT_LUGGAGE = 'LEFT_LUGGAGE',
        PORTERAGE = 'PORTERAGE',
        BAGGAGE_TROLLEYS = 'BAGGAGE_TROLLEYS',
    }
    export enum mobilityFacility {
        PTI_23_255_4 = 'PTI_23_255_4',
        UNKNOWN = 'UNKNOWN',
        PTI_23_16 = 'PTI_23_16',
        SUITABLE_FOR_WHEEL_CHAIRS = 'SUITABLE_FOR_WHEEL_CHAIRS',
        PTI_23_16_1 = 'PTI_23_16_1',
        LOW_FLOOR = 'LOW_FLOOR',
        PTI_23_16_2 = 'PTI_23_16_2',
        BOARDING_ASSISTANCE = 'BOARDING_ASSISTANCE',
        PTI_23_16_3 = 'PTI_23_16_3',
        STEP_FREE_ACCESS = 'STEP_FREE_ACCESS',
        TACTILE_PATFORM_EDGES = 'TACTILE_PATFORM_EDGES',
        ONBOARD_ASSISTANCE = 'ONBOARD_ASSISTANCE',
        UNACCOMPANIED_MINOR_ASSISTANCE = 'UNACCOMPANIED_MINOR_ASSISTANCE',
        AUDIO_INFORMATION = 'AUDIO_INFORMATION',
        VISUAL_INFORMATION = 'VISUAL_INFORMATION',
        DISPLAYS_FOR_VISUALLY_IMPAIRED = 'DISPLAYS_FOR_VISUALLY_IMPAIRED',
        AUDIO_FOR_HEARING_IMPAIRED = 'AUDIO_FOR_HEARING_IMPAIRED',
    }
    export enum nuisanceFacility {
        UNKNOWN = 'UNKNOWN',
        SMOKING = 'SMOKING',
        NO_SMOKING = 'NO_SMOKING',
        MOBILE_PHONE_USE_ZONE = 'MOBILE_PHONE_USE_ZONE',
        MOBILE_PHONE_FREE_ZONE = 'MOBILE_PHONE_FREE_ZONE',
    }
    export enum parkingFacility {
        UNKNOWN = 'UNKNOWN',
        CAR_PARK = 'CAR_PARK',
        PARK_AND_RIDE_PARK = 'PARK_AND_RIDE_PARK',
        MOTORCYCLE_PARK = 'MOTORCYCLE_PARK',
        CYCLE_PARK = 'CYCLE_PARK',
        RENTAL_CAR_PARK = 'RENTAL_CAR_PARK',
        COACH_PARK = 'COACH_PARK',
    }
    export enum passengerCommsFacility {
        UNKNOWN = 'UNKNOWN',
        FACCOMMS_1 = 'FACCOMMS_1',
        PASSENGER_WIFI = 'PASSENGER_WIFI',
        PTI_23_21 = 'PTI_23_21',
        TELEPHONE = 'TELEPHONE',
        PTI_23_14 = 'PTI_23_14',
        AUDIO_SERVICES = 'AUDIO_SERVICES',
        PTI_23_15 = 'PTI_23_15',
        VIDEO_SERVICES = 'VIDEO_SERVICES',
        PTI_23_25 = 'PTI_23_25',
        BUSINESS_SERVICES = 'BUSINESS_SERVICES',
        INTERNET = 'INTERNET',
        POSTOFFICE = 'POSTOFFICE',
        LETTERBOX = 'LETTERBOX',
    }
    export enum passengerInformationFacility {
        UNKNOWN = 'UNKNOWN',
        NEXT_STOP_INDICATOR = 'NEXT_STOP_INDICATOR',
        STOP_ANNOUNCEMENTS = 'STOP_ANNOUNCEMENTS',
        PASSENGER_INFORMATION_DISPLAY = 'PASSENGER_INFORMATION_DISPLAY',
        AUDIO_INFORMATION = 'AUDIO_INFORMATION',
        VISUAL_INFORMATION = 'VISUAL_INFORMATION',
        TACTILE_PLATFORM_EDGES = 'TACTILE_PLATFORM_EDGES',
        TACTILE_INFORMATION = 'TACTILE_INFORMATION',
        WALKING_GUIDANCE = 'WALKING_GUIDANCE',
        JOURNEY_PLANNING = 'JOURNEY_PLANNING',
        LOST_FOUND = 'LOST_FOUND',
        INFORMATION_DESK = 'INFORMATION_DESK',
        INTERACTIVE_KIOSK_DISPLAY = 'INTERACTIVE_KIOSK_DISPLAY',
        PRINTED_PUBLIC_NOTICE = 'PRINTED_PUBLIC_NOTICE',
    }
    export enum refreshmentFacility {
        UNKNOWN = 'UNKNOWN',
        PTI_23_1 = 'PTI_23_1',
        RESTAURANT_SERVICE = 'RESTAURANT_SERVICE',
        PTI_23_2 = 'PTI_23_2',
        SNACKS_SERVICE = 'SNACKS_SERVICE',
        PTI_23 = 'PTI_23',
        TROLLEY = 'TROLLEY',
        PTI_23_18 = 'PTI_23_18',
        BAR = 'BAR',
        PTI_23_19 = 'PTI_23_19',
        FOOD_NOT_AVAILABLE = 'FOOD_NOT_AVAILABLE',
        PTI_23_20 = 'PTI_23_20',
        BEVERAGES_NOT_AVAILABLE = 'BEVERAGES_NOT_AVAILABLE',
        PTI_23_26 = 'PTI_23_26',
        BISTRO = 'BISTRO',
        FOOD_VENDING_MACHINE = 'FOOD_VENDING_MACHINE',
        BEVERAGE_VENDING_MACHINE = 'BEVERAGE_VENDING_MACHINE',
    }
    export enum reservedSpaceFacility {
        UNKNOWN = 'UNKNOWN',
        LOUNGE = 'LOUNGE',
        HALL = 'HALL',
        MEETINGPOINT = 'MEETINGPOINT',
        GROUP_POINT = 'GROUP_POINT',
        RECEPTION = 'RECEPTION',
        SHELTER = 'SHELTER',
        SEATS = 'SEATS',
    }
    export enum retailFacility {
        UNKNOWN = 'UNKNOWN',
        FOOD = 'FOOD',
        NEWSPAPER_TOBACCO = 'NEWSPAPER_TOBACCO',
        RECREATION_TRAVEL = 'RECREATION_TRAVEL',
        HYGIENE_HEALTH_BEAUTY = 'HYGIENE_HEALTH_BEAUTY',
        FASHION_ACCESSORIES = 'FASHION_ACCESSORIES',
        BANK_FINANCE_INSURANCE = 'BANK_FINANCE_INSURANCE',
        CASH_MACHINE = 'CASH_MACHINE',
        CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE',
        TOURISM_SERVICE = 'TOURISM_SERVICE',
        PHOTO_BOOTH = 'PHOTO_BOOTH',
    }
    export enum sanitaryFacility {
        UNKNOWN = 'UNKNOWN',
        PTI_23_22 = 'PTI_23_22',
        TOILET = 'TOILET',
        PTI_23_23 = 'PTI_23_23',
        NO_TOILET = 'NO_TOILET',
        SHOWER = 'SHOWER',
        WHEELCHAIR_ACCCESS_TOILET = 'WHEELCHAIR_ACCCESS_TOILET',
        BABY_CHANGE = 'BABY_CHANGE',
    }
    export enum ticketingFacility {
        UNKNOWN = 'UNKNOWN',
        TICKET_MACHINES = 'TICKET_MACHINES',
        TICKET_OFFICE = 'TICKET_OFFICE',
        TICKET_ON_DEMAND_MACHINES = 'TICKET_ON_DEMAND_MACHINES',
        TICKET_SALES = 'TICKET_SALES',
        MOBILE_TICKETING = 'MOBILE_TICKETING',
        TICKET_COLLECTION = 'TICKET_COLLECTION',
        CENTRAL_RESERVATIONS = 'CENTRAL_RESERVATIONS',
        LOCAL_TICKETS = 'LOCAL_TICKETS',
        NATIONAL_TICKETS = 'NATIONAL_TICKETS',
        INTERNATIONAL_TICKETS = 'INTERNATIONAL_TICKETS',
    }
}

