import React, { forwardRef, ReactNode } from 'react';
import { darken, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.primary.main,
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),

    '&:hover': {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },

    '& svg': {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
  },
}));

type CustomIconButtonProps = {
  title?: string;
  className?: string;
  clickAwayIgnore?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
};

export const CustomIconButton = forwardRef<HTMLButtonElement, CustomIconButtonProps>(
  ({ title, className, onClick, children, clickAwayIgnore }: CustomIconButtonProps, ref) => {
    const classes = useStyles();

    return (
      <IconButton
        title={title}
        data-click-away-ignore={clickAwayIgnore}
        ref={ref}
        className={clsx(classes.root, className)}
        onClick={onClick}
        size="large"
      >
        {children}
      </IconButton>
    );
  },
);
