import React from 'react';

import { Map } from '../containers/Map';
import { RoutingWizard } from '../components/RoutingWizard';
import { PageDivider } from '../components/PageDivider';
import { Footer } from '../components/Footer';

export const StartPage = () => (
  <PageDivider map={<Map />}>
    <RoutingWizard />

    <Footer />
  </PageDivider>
);
