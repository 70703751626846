import { Place } from '../types/Place';
import { FeatureItem } from './api-geocoding';

export class GeocoderFeatureService {
  public static getNameFromFeature({ properties }: FeatureItem): Place['name'] {
    const { osm_key: osmKey, osm_value: osmValue, name, street, housenumber, postcode, city, country } = properties;

    if (osmKey === 'place' && osmValue === 'town' && postcode && name && country) {
      return {
        full: `${postcode} ${name}, ${country}`,
        firstLine: `${postcode} ${name}`,
        secondLine: country,
      };
    }

    const displayName: string[] = [];

    if (name) {
      displayName.push(name);
    }

    if (street) {
      if (housenumber) {
        displayName.push(`${street} ${housenumber}`);
      } else {
        displayName.push(street);
      }
    }

    if (postcode && city) {
      displayName.push(`${postcode} ${city}`);
    } else {
      if (postcode) {
        displayName.push(postcode);
      }

      if (city) {
        displayName.push(city);
      }
    }

    if (country) {
      displayName.push(country);
    }

    const [firstLine, ...secondLine] = displayName;

    return {
      full: displayName.join(', '),
      firstLine,
      secondLine: secondLine.join(', '),
    };
  }

  public static transformToPlace(feature: FeatureItem): Place {
    return {
      osmId: feature.properties.osm_id,
      name: GeocoderFeatureService.getNameFromFeature(feature),
      lng: feature.geometry.coordinates[0],
      lat: feature.geometry.coordinates[1],
    };
  }

  public static areSamePlaces(placeA: Place, placeB: Place) {
    return (
      placeA.osmId === placeB.osmId &&
      placeA.name.full === placeB.name.full &&
      placeA.lng === placeB.lng &&
      placeA.lat === placeB.lat
    );
  }
}
