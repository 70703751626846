import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: 0,
  },
  switch: {
    width: theme.spacing(8),
    height: theme.spacing(4),
    padding: 0,
    marginLeft: theme.spacing(2),
  },
  switchBase: {
    padding: theme.spacing(0.5),
    '&$checked': {
      transform: `translateX(${theme.spacing(4)})`,
      color: theme.palette.common.white,

      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: theme.spacing(8 / 2),
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.grey[200],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
  borderSwitch: {
    borderColor: theme.palette.common.white,
  },
}));

type CustomSwitchProps = {
  name: string;
  label: string;
  checked: boolean;
  onChange: () => void;
  border?: boolean;
};

export const CustomSwitch = ({ name, checked, label, onChange, border = false }: CustomSwitchProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{
        root: classes.root,
      }}
      control={
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.switch,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: clsx(classes.track, border && classes.borderSwitch),
            checked: classes.checked,
          }}
          name={name}
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};
