import React from 'react';
import { Chip, Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useStore } from '../setup/global-state';

const useStyles = makeStyles((theme) => ({
  rainRadarChip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    border: 'none',
    position: 'absolute',
    top: theme.spacing(-1.5),
    left: '50%',
    fontSize: '0.75rem',
    minWidth: theme.spacing(5),
    height: theme.spacing(3),

    '& .MuiChip-label': {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
}));

type RainRadarOffsetChipProps = { open: boolean };
export const RainRadarOffsetChip = ({ open }: RainRadarOffsetChipProps) => {
  const rainRadarOffset = useStore((state) => state.rainRadarOffset);
  const classes = useStyles();

  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <Chip variant="outlined" label={rainRadarOffset} className={classes.rainRadarChip} />
    </Fade>
  );
};
