import React from 'react';
import { Box, Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import sliderArrows from '../assets/icons/forms/slider-arrows.svg';

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    position: 'relative',
    paddingRight: theme.spacing(3.5),
    paddingLeft: theme.spacing(3.5),
    margin: 0,
    paddingTop: theme.spacing(3),

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      height: theme.spacing(7),
      border: `2px solid ${theme.palette.grey[200]}`,
    },
  },
  sliderRoot: {
    display: 'block',
    width: 'auto',
    height: theme.spacing(7),
    padding: 0,
    cursor: 'default',
  },
  thumb: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 0,
    backgroundColor: theme.palette.grey[200],
    margin: 0,
    top: 0,
    transform: 'translate(-50%)',
    cursor: 'pointer',
    backgroundImage: `url(${sliderArrows})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: theme.spacing(2.5),
  },
  active: {},
  valueLabel: {
    bottom: theme.spacing(7),
    left: 0,
    right: 0,
    transform: 'none',
    background: 'transparent',
    width: 'auto',
    height: 'auto',

    '&::before': {
      display: 'none',
    },

    '& > span > span': {
      background: 'transparent',
      color: theme.palette.text.primary,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
  },
  rail: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
  },
}));

type CustomSliderProps = {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
  valueLabelFormat?: (value: number) => string;
};

export const CustomSlider = ({ value, min, max, step, onChange, valueLabelFormat }: CustomSliderProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.sliderWrapper}>
      <Slider
        classes={{
          root: classes.sliderRoot,
          thumb: classes.thumb,
          active: classes.active,
          valueLabel: classes.valueLabel,
          rail: classes.rail,
        }}
        min={min}
        max={max}
        step={step}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
        value={value}
        onChange={(event, newValue) => onChange(newValue as number)}
        track={false}
      />
    </Box>
  );
};
