import React from 'react';

import { LayersControls } from './LayersControls';
import { ControlsPopper } from './ControlsPopper';

type LayersPopperProps = {
  anchor?: HTMLButtonElement;
  onClose: () => void;
};

export const LayersPopper = ({ anchor, onClose }: LayersPopperProps) => (
  <ControlsPopper anchor={anchor} onClose={onClose}>
    <LayersControls />
  </ControlsPopper>
);
