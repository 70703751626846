import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RouteSelect } from '../containers/RouteSelect';
import { RoutingForm } from '../containers/RoutingForm';
import { useStore } from '../setup/global-state';
import { PlaceType } from '../types/PlaceType';
import { IntroductionDialog } from './IntroductionDialog';
import { TipsDialog } from './TipsDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: `${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    maxWidth: theme.spacing(54),
    margin: '0 auto',

    [theme.breakpoints.up('md')]: {
      gridArea: 'main',
      maxWidth: theme.spacing(58),
      justifySelf: 'center',
      paddingRight: theme.spacing(7),
      paddingLeft: theme.spacing(7),
    },
  },
}));

export const RoutingWizard = () => {
  const classes = useStyles();
  const origin = useStore((state) => state.places[PlaceType.ORIGIN]);
  const destination = useStore((state) => state.places[PlaceType.DESTINATION]);

  const [step, setStep] = useState(origin && destination ? 1 : 0);

  useEffect(() => {
    setStep(origin && destination ? 1 : 0);
  }, [origin, destination]);

  return (
    <Box component="main" className={classes.root}>
      {step === 0 && (
        <>
          <RoutingForm />
          <IntroductionDialog />
        </>
      )}
      {step === 1 && (
        <>
          <RouteSelect />
          <TipsDialog />
        </>
      )}
    </Box>
  );
};
