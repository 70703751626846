import { Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ContentPageWrapper } from '../components/ContentPageWrapper';

const useStyles = makeStyles(() => ({
  content: {
    maxWidth: 800,
  },
}));

export const PrivacyPage = () => {
  const classes = useStyles();

  return (
    <ContentPageWrapper>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item className={classes.content}>
          <Typography gutterBottom variant="h2">
            Datenschutzerklärung
          </Typography>
          <Typography gutterBottom variant="h4">
            Allgemeine Hinweise
          </Typography>
          <Typography gutterBottom>
            Wir freuen uns über Ihren Besuch auf unserer Webseite und über Ihr Interesse an unserem Service. Die
            Radlkarte Salzburg ist als Innovations- und Entwicklungsplattform gedacht, die interessierten NutzerInnen
            neue Technologien und Funktionen im Sinne eines Reallabors zur Verfügung stellt.
          </Typography>
          <Typography gutterBottom>
            Diese Datenschutzerklärung bezieht sich auf die Verarbeitung personenbezogener Daten im Rahmen des
            Internetauftritts <Link href="https://www.radlkarte.info">https://www.radlkarte.info</Link>.
          </Typography>
          <Typography gutterBottom>
            Für nähere Informationen zur Verarbeitung Ihrer personenbezogenen Daten können Sie uns unter den folgenden
            Kontaktdaten erreichen.
          </Typography>
          <Typography gutterBottom>Die Radlkarte wird im Auftrag von</Typography>
          <Typography variant="h5">Land Salzburg</Typography>
          <Typography>Referat 6/12 - Öffentlicher Verkehr und Verkehrsplanung</Typography>
          <Typography>Michael-Pacher-Straße 36</Typography>
          <Typography>5020 Salzburg</Typography>
          <Typography>Telefon: +43 662 8042-4569</Typography>
          <Typography gutterBottom>
            E-Mail: <Link href="mailto:mobil@salzburg.gv.at">mobil@salzburg.gv.at</Link>
          </Typography>
          <Typography gutterBottom>und</Typography>
          <Typography variant="h5">Magistrat Salzburg </Typography>
          <Typography>MA 5/03 - Amt für Stadtplanung und Verkehr</Typography>
          <Typography>Schwarzstraße 44</Typography>
          <Typography>5024 Salzburg</Typography>
          <Typography>Telefon: +43 662 8072 2680 </Typography>
          <Typography gutterBottom>
            E-Mail: <Link href="mailto:stadtplanung@stadt-salzburg.at">stadtplanung@stadt-salzburg.at</Link>
          </Typography>
          <Typography gutterBottom>durch</Typography>
          <Typography variant="h5">Trafficon – Traffic Consultants GmbH</Typography>
          <Typography>Strubergasse 26 </Typography>
          <Typography>5020 Salzburg</Typography>
          <Typography>Telefon: +43 662 46 11 02-0</Typography>
          <Typography gutterBottom>
            E-Mail: <Link href="mailto:info@trafficon.eu">info@trafficon.eu</Link>
          </Typography>
          <Typography gutterBottom>betrieben.</Typography>
          <Typography gutterBottom>
            Wir nehmen Ihren Datenschutz sehr ernst und behandeln Ihre personenbezogenen Daten vertraulich und
            entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige Weiterentwicklung
            dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen
            sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
          </Typography>
          <Typography gutterBottom variant="h4">
            Daten, Zwecke, Speicherdauer
          </Typography>
          <Typography gutterBottom>
            Wir erheben aufgrund unseres berechtigten Interesses Daten über Zugriffe auf die Website und speichern diese
            als „Server-Logfiles“ auf dem Server der Webseite ab. Folgende Daten werden so protokolliert:
            <ul>
              <li>Besuchte Website</li>
              <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
              <li>Menge der gesendeten Daten in Byte</li>
              <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
              <li>Verwendeter Browser</li>
              <li>Verwendetes Betriebssystem</li>
              <li>Verwendete IP-Adresse</li>
            </ul>
          </Typography>
          <Typography gutterBottom>
            Die Server-Logfiles werden für maximal 180 Tage gespeichert und anschließend gelöscht. Die Speicherung der
            Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus
            Beweisgründen aufgehoben werden, sind sie so lange von der Löschung ausgenommen, bis der Vorfall endgültig
            geklärt ist.
          </Typography>
          <Typography gutterBottom>
            Für die Nutzung der Radlkarte ist die Eingabe von Start- und Zielpunkten in Form einer textuellen Adresse
            (Geocoding-Service) oder mittels Auswahl von geographischen Koordinaten in der Karte erforderlich. Wenn vom
            Nutzer erwünscht und am Endgerät zugelassen, kann auch der aktuelle Standort als Start- oder Zielpunkt
            verwendet werden. Die Adressdaten werden zum Zweck der Berechnung von Routenvorschlägen verarbeitet und
            nicht gespeichert.
          </Typography>
          <Typography gutterBottom variant="h4">
            SSL-Verschlüsselung
          </Typography>
          <Typography gutterBottom>
            Unser Internetportal verwendet aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
            Inhalte, wie zum Beispiel von Anfragen, die Nutzer über das Portal an uns senden, eine SSL-Verschlüsselung.
            Eine verschlüsselte Verbindung erkennt man daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und die Browserzeile ein Vorhängeschloss-Symbol enthält.
          </Typography>
          <Typography gutterBottom>
            Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die vom Nutzer an uns übermittelt werden, im
            Regelfall nicht von Dritten mitgelesen werden.
          </Typography>
          <Typography gutterBottom variant="h4">
            Kartenapplikation Mapbox
          </Typography>
          <Typography gutterBottom>
            Wir haben auf unserer Webseite den Kartendiensts Mapbox der Mapbox Inc., 740 15th Street NW, 5th Floor,
            Washington, District of Columbia 20005, USA, eingebunden. Das führt dazu, dass beim Aufruf unserer Webseite
            Daten wie Ihre IP-Adresse von Mapbox an Server in die USA übertragen und gespeichert werden.
          </Typography>
          <Typography gutterBottom>
            Nähere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten und Möglichkeiten zum Schutz
            Ihrer Privatsphäre entnehmen Sie bitte der Website von Mapbox:
          </Typography>
          <Typography gutterBottom>
            Mapbox Datenschutzerklärung: <Link href="https://www.mapbox.com/legal/privacy">Link</Link>
          </Typography>
          <Typography gutterBottom variant="h4">
            Links zu Webseiten Dritter
          </Typography>
          <Typography gutterBottom>
            In der Radlkarte Salzburg sind Links zu Webseiten Dritter enthalten. Für deren Inhalte und Einhaltung der
            Datenschutzbestimmungen wird keine Gewährleistung übernommen.
          </Typography>
          <Typography gutterBottom variant="h4">
            Webhosting
          </Typography>
          <Typography gutterBottom>
            Für die Webseite nutzen wir einen Webhosting-Anbieter. Dieser ist das Unternehmen Hetzner Online GmbH,
            Industriestr. 25, 91710 Gunzenhausen, Deutschland. Mehr Informationen zum Datenschutz der Firma Hetzner GmbH
            finden Sie <Link href="https://www.hetzner.com/de/rechtliches/datenschutz/">hier</Link>
          </Typography>
          <Typography gutterBottom variant="h4">
            Datensicherheitsmaßnahmen
          </Typography>
          <Typography gutterBottom>
            Die Sicherheit Ihrer Daten ist uns ein großes Anliegen. Zum Schutz Ihrer Daten setzen wir daher auf
            technische und organisatorische Schutzmaßnahmen. Zu diesen zählen unter anderem:
            <ul>
              <li>Technische und organisatorische Maßnahmen zum Schutz der datenverarbeitenden Systeme; </li>
              <li>
                Beschränkung der Zugriffe auf jene Personenkreise, welche die Daten zur Zweckerfüllung benötigen;{' '}
              </li>
              <li>
                Verpflichtung der mit der Datenverarbeitung betrauten Mitarbeiterinnen und Mitarbeiter zur
                Geheimhaltung;{' '}
              </li>
              <li>Vertragliche Vereinbarungen Dienstleistern (Auftragsverarbeiter). </li>
            </ul>
          </Typography>
          <Typography gutterBottom variant="h4">
            Ihre Betroffenenrechte
          </Typography>
          <Typography gutterBottom>
            Sie haben grundsätzlich das Recht auf Auskunft durch die Verantwortlichen über die Sie betreffenden
            personenbezogenen Daten, sowie das Recht auf Berichtigung, Löschung, Einschränkung und Datenübertragbarkeit.
            Unter bestimmten Umständen können Sie der Verarbeitung der Sie betreffenden personenbezogenen Daten
            widersprechen. Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht
            verstößt, besteht ein Beschwerderecht bei der Aufsichtsbehörde. In Österreich ist dies die
            Datenschutzbehörde. Die Kontaktinformationen finden Sie unter{' '}
            <Link href="https://edpb.europa.eu/about-edpb/board/members">hier</Link>
          </Typography>
          <Typography gutterBottom variant="h4">
            Verantwortlicher und Anlaufstelle für Betroffene
          </Typography>
          <Typography gutterBottom>
            Das Magistrat der Stadt Salzburg ist Datenschutzverantwortlicher im Sinne der DSGVO für die
            personenbezogenen Daten. Ihre Rechte können Sie per E-Mail oder per Post gegenüber dem Verantwortlichen
            ausüben:
          </Typography>
          <Typography>Magistrat der Stadt Salzburg</Typography>
          <Typography>Mirabellplatz 4</Typography>
          <Typography>5020 Salzburg</Typography>
          <Typography gutterBottom>
            E-Mail: <Link href="mailto:verantwortlicher@stadt-salzburg.at">verantwortlicher@stadt-salzburg.at</Link>
          </Typography>
        </Grid>
      </Grid>
    </ContentPageWrapper>
  );
};
