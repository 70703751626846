import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';

import { PointOfInterestPopUp } from '../components/PointOfInterestPopUp';
import { PointsOfInterestType } from '../types/PointsOfInterestType';
import { theme } from '../setup/theme';
import PoiClusterBackground from '../assets/icons/map/poi-cluster-background.png';
import { PointsOfInterestService } from './PointsOfInterestService';
import { MapHelperService } from './MapHelperService';

export class MapPointsOfInterestService {
  private static id = {
    source: 'points-of-interest-source',
    clusterBackground: 'points-of-interest-cluster-background',
    clusterCount: 'points-of-interest-cluster-count',
    unclusteredPoints: 'points-of-interest-unclustered-points',
  };

  public static loadImages(map: mapboxgl.Map) {
    Object.values(PointsOfInterestType).forEach((type) => {
      MapPointsOfInterestService.loadImage(map, PointsOfInterestService.typeToImage(type), type);
    });

    MapPointsOfInterestService.loadImage(map, PoiClusterBackground, MapPointsOfInterestService.id.clusterBackground);
  }

  private static loadImage(map: mapboxgl.Map, url: string, name: string) {
    map.loadImage(url, (error, image) => {
      if (error) throw error;

      if (image) {
        map.addImage(name, image);
      }
    });
  }

  public static create(map: mapboxgl.Map, visible: boolean, poiPopUp: MutableRefObject<mapboxgl.Popup | undefined>) {
    map.addSource(MapPointsOfInterestService.id.source, {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
      data: `${process.env.REACT_APP_POI_URL}/point-of-interest.json`,
    });

    map.addLayer({
      id: MapPointsOfInterestService.id.clusterCount,
      source: MapPointsOfInterestService.id.source,
      type: 'symbol',
      filter: ['has', 'point_count'],
      layout: {
        visibility: MapHelperService.getVisibility(visible),
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Pro Bold'],
        'icon-image': MapPointsOfInterestService.id.clusterBackground,
        'icon-size': 0.5,
      },
      paint: {
        'text-color': '#834F68',
      },
    });

    map.addLayer({
      id: MapPointsOfInterestService.id.unclusteredPoints,
      source: MapPointsOfInterestService.id.source,
      type: 'symbol',
      filter: ['!', ['has', 'point_count']],
      layout: {
        visibility: MapHelperService.getVisibility(visible),
        'icon-image': [
          'case',
          ['has', 'Typ'],
          ['get', 'Typ'],
          ['has', 'Haltestellenname'],
          PointsOfInterestType.CITY_TRAIN_STATION,
          '',
        ],
        'icon-size': 0.5,
      },
    });

    map.on('click', MapPointsOfInterestService.id.unclusteredPoints, (event) => {
      const coordinates = (event.features?.[0].geometry as any).coordinates.slice();
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      if (
        poiPopUp.current &&
        poiPopUp.current?.getLngLat().lng === coordinates[0] &&
        poiPopUp.current?.getLngLat().lat === coordinates[1]
      ) {
        poiPopUp.current?.remove();
        // eslint-disable-next-line no-param-reassign
        poiPopUp.current = undefined;
      } else {
        const popupContent = document.createElement('div');
        if (event.features?.[0]) {
          ReactDOM.render(
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <PointOfInterestPopUp
                  feature={event.features?.[0]}
                  onClose={() => {
                    poiPopUp.current?.remove();
                    // eslint-disable-next-line no-param-reassign
                    poiPopUp.current = undefined;
                  }}
                />
              </ThemeProvider>
            </StyledEngineProvider>,
            popupContent,
          );
        }

        // eslint-disable-next-line no-param-reassign
        poiPopUp.current = new mapboxgl.Popup({
          offset: 28,
          focusAfterOpen: false,
          closeButton: false,
          maxWidth: 'min(480px, 75vw)',
          className: 'point-of-interest-pop-up',
        });
        poiPopUp.current.setDOMContent(popupContent).setLngLat(coordinates).addTo(map);
      }
    });
  }

  public static update(map: mapboxgl.Map, visible: boolean) {
    if (map.isStyleLoaded()) {
      [MapPointsOfInterestService.id.clusterCount, MapPointsOfInterestService.id.unclusteredPoints].forEach((layer) =>
        map.setLayoutProperty(layer, 'visibility', MapHelperService.getVisibility(visible)),
      );
    }
  }
}
