import React from 'react';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useStore } from '../setup/global-state';
import { TipsDialogStep } from '../types/TipsDialogStep';
import { CustomSlider } from './CustomSlider';
import { ControlsSection } from './ControlsSection';
import { CustomSwitch } from './CustomSwitch';
import { CustomSliderWrapper } from './CustomSliderWrapper';

const useStyles = makeStyles((theme) => ({
  description: {
    maxWidth: theme.spacing(45),
    fontSize: '0.8rem',
  },
}));

type SettingsControlsProps = {
  onClose?: () => void;
};

export const SettingsControls = ({ onClose }: SettingsControlsProps) => {
  const classes = useStyles();

  const speed = useStore((state) => state.speed);
  const introductionDialogVisible = useStore((state) => state.introductionDialogVisible);
  const tipsDialogVisible = useStore((state) => state.tipsDialogVisible);
  const updateSpeed = useStore((state) => state.setSpeed);
  const setIntroductionDialogVisible = useStore((state) => state.setIntroductionDialogVisible);
  const setTipsDialogVisible = useStore((state) => state.setTipsDialogVisible);
  const setTipsDialogStep = useStore((state) => state.setTipsDialogStep);
  const setPageDividerOpen = useStore((state) => state.setPageDividerOpen);

  return (
    <>
      <ControlsSection>
        <CustomSliderWrapper label="Geschwindigkeit">
          <CustomSlider
            value={speed}
            min={5}
            max={50}
            step={1}
            onChange={(value: number) => updateSpeed(value)}
            valueLabelFormat={(value: number) => `${value.toLocaleString()} km/h`}
          />
        </CustomSliderWrapper>

        <FormHelperText className={classes.description}>
          ⌀ Radfahren 15 km/h (10 - 25 km/h) | E-Bike max. 25 km/h | ⌀ Tour de France 41 km/h | ⌀ Zu Fuß 3,6 km/h
        </FormHelperText>
      </ControlsSection>

      <ControlsSection>
        <CustomSwitch
          name="dialogs-visible"
          label="Tipps anzeigen"
          checked={introductionDialogVisible || tipsDialogVisible}
          onChange={() => {
            if (introductionDialogVisible || tipsDialogVisible) {
              setIntroductionDialogVisible(false);
              setTipsDialogVisible(false);
              setTipsDialogStep(TipsDialogStep.DISABLED);
            } else {
              setPageDividerOpen(true);
              setIntroductionDialogVisible(true);
              setTipsDialogVisible(true);

              if (onClose) {
                onClose();
              }
            }
          }}
        />
      </ControlsSection>
    </>
  );
};
