/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { LinkProjectionStructure } from './LinkProjectionStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { OffsetStructure } from './OffsetStructure';
export type AffectedPathLinkStructure = {
    accessibilityFeature?: AffectedPathLinkStructure.accessibilityFeature;
    extensions?: ExtensionsStructure;
    linkDirection?: Array<string>;
    linkName?: NaturalLanguageStringStructure;
    linkProjection?: LinkProjectionStructure;
    linkRef?: Array<string>;
    offset?: OffsetStructure;
};
export namespace AffectedPathLinkStructure {
    export enum accessibilityFeature {
        LIFT = 'LIFT',
        STAIRS = 'STAIRS',
        SERIES_OF_STAIRS = 'SERIES_OF_STAIRS',
        ESCALATOR = 'ESCALATOR',
        TRAVELATOR = 'TRAVELATOR',
        RAMP = 'RAMP',
        SHUTTLE = 'SHUTTLE',
        BARRIER = 'BARRIER',
        NARROW_ENTRANCE = 'NARROW_ENTRANCE',
        CONFINED_SPACE = 'CONFINED_SPACE',
        QUEUE_MANAGEMENT = 'QUEUE_MANAGEMENT',
        NONE = 'NONE',
        UNKNOWN = 'UNKNOWN',
        OTHER = 'OTHER',
    }
}

