import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ContentPageWrapper } from '../components/ContentPageWrapper';

const useStyles = makeStyles((theme) => ({
  item: {
    maxWidth: 500,
  },
  contacts: {
    marginBottom: theme.spacing(2.5),
  },
}));

export const ContactPage = () => {
  const classes = useStyles();

  return (
    <ContentPageWrapper>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item className={classes.item}>
          <Typography gutterBottom variant="h2">
            Auftraggeber / Allgemeine Fragen
          </Typography>
          <Box className={classes.contacts}>
            <Typography>Magistrat der Stadt Salzburg</Typography>
            <Link href="https://www.stadt-salzburg.at" target="_blank" rel="noreferrer" display="block">
              www.stadt-salzburg.at
            </Link>
          </Box>

          <Box className={classes.contacts}>
            <Typography>Land Salzburg</Typography>
            <Link href="https://www.salzburg.gv.at" target="_blank" rel="noreferrer" display="block">
              www.salzburg.gv.at
            </Link>
          </Box>
          <Typography gutterBottom>
            <Link href="mailto:office@salzburgrad.at">office@salzburgrad.at</Link>
          </Typography>

          <Typography gutterBottom variant="h2">
            Technische Umsetzung
          </Typography>
          <Box className={classes.contacts}>
            <Typography>Trafficon - Traffic Consultants GmbH</Typography>
            <Link href="mailto:info@trafficon.eu">info@trafficon.eu</Link>
            <Link href="https://www.trafficon.eu" target="_blank" rel="noreferrer" display="block">
              www.trafficon.eu
            </Link>
          </Box>
        </Grid>
      </Grid>
    </ContentPageWrapper>
  );
};
