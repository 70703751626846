import mapboxgl from 'mapbox-gl';

import { RouteType } from '../types/RouteType';
import { GlobalState } from '../types/GlobalState';
import { Trias } from './api-routing';
import { RoutePropertyService } from './RoutePropertyService';
import { MapHelperService } from './MapHelperService';

export class MapRouteService {
  private static getSourceId(routeType: RouteType) {
    return `${routeType}-source`;
  }

  private static getRouteId(routeType: RouteType) {
    return `${routeType}-route`;
  }

  public static create(
    map: mapboxgl.Map,
    routes: Record<RouteType, Trias | undefined>,
    selectedRoute: GlobalState['selectedRoute'],
  ) {
    [RouteType.FASTEST, RouteType.RECOMMENDED].forEach((routeType) => {
      map.addSource(MapRouteService.getSourceId(routeType), {
        type: 'geojson',
        data: RoutePropertyService.getGeoJsonFromRoute(
          routes[routeType]?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult?.[0].route,
        ),
      });

      map.addLayer({
        id: MapRouteService.getRouteId(routeType),
        source: MapRouteService.getSourceId(routeType),
        type: 'line',
        paint: {
          'line-color': MapRouteService.getLineColor(routeType, selectedRoute),
          'line-width': 6,
          'line-opacity': 0.8,
        },
        layout: {
          visibility: MapHelperService.getVisibility(!!routes[routeType]),
          'line-cap': 'round',
        },
      });
    });
  }

  public static update(map: mapboxgl.Map, routes: Record<RouteType, Trias | undefined>) {
    if (
      map.getSource(MapRouteService.getSourceId(RouteType.RECOMMENDED)) &&
      map.isSourceLoaded(MapRouteService.getSourceId(RouteType.RECOMMENDED)) &&
      map.getSource(MapRouteService.getSourceId(RouteType.FASTEST)) &&
      map.isSourceLoaded(MapRouteService.getSourceId(RouteType.FASTEST))
    ) {
      [RouteType.RECOMMENDED, RouteType.FASTEST].forEach((routeType) => {
        const source = map.getSource(MapRouteService.getSourceId(routeType));

        if (routes[routeType]?.serviceDelivery?.status && source?.type === 'geojson') {
          source.setData(
            RoutePropertyService.getGeoJsonFromRoute(
              routes[routeType]?.serviceDelivery?.deliveryPayload?.individualRouteResponse?.routeResult?.[0].route,
            ),
          );
        }

        map.setLayoutProperty(
          MapRouteService.getRouteId(routeType),
          'visibility',
          MapHelperService.getVisibility(!!routes[routeType]?.serviceDelivery?.status),
        );
      });
    }
  }

  public static updateSelected(map: mapboxgl.Map, selectedRoute: GlobalState['selectedRoute']) {
    if (map.isStyleLoaded()) {
      [RouteType.RECOMMENDED, RouteType.FASTEST].forEach((routeType) => {
        map.setPaintProperty(
          MapRouteService.getRouteId(routeType),
          'line-color',
          MapRouteService.getLineColor(routeType, selectedRoute),
        );
      });
      map.moveLayer(
        MapRouteService.getRouteId(
          { [RouteType.RECOMMENDED]: RouteType.FASTEST, [RouteType.FASTEST]: RouteType.RECOMMENDED }[
            selectedRoute.active
          ],
        ),
        MapRouteService.getRouteId(selectedRoute.active),
      );
    }
  }

  private static getLineColor(routeType: RouteType, selectedRoute: GlobalState['selectedRoute']) {
    if (routeType === selectedRoute.active) {
      return '#00AEEA';
    }

    if (routeType === selectedRoute.hover) {
      return '#52c6e8';
    }

    return '#86888C';
  }
}
