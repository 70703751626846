/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DirectionRefStructure } from './DirectionRefStructure';
import type { GeneralAttributeStructure } from './GeneralAttributeStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { JourneyRefStructure } from './JourneyRefStructure';
import type { LineRefStructure } from './LineRefStructure';
import type { ModeStructure } from './ModeStructure';
import type { OperatingDayRefStructure } from './OperatingDayRefStructure';
import type { OperatorRefStructure } from './OperatorRefStructure';
import type { ServiceViaPointStructure } from './ServiceViaPointStructure';
import type { SituationFullRefStructure } from './SituationFullRefStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
import type { VehicleRefStructure } from './VehicleRefStructure';
export type DatedJourneyStructure = {
    attribute?: Array<GeneralAttributeStructure>;
    cancelled?: boolean;
    destinationStopPointRef?: StopPointRefStructure;
    destinationText?: InternationalTextStructure;
    deviation?: boolean;
    directionRef?: DirectionRefStructure;
    journeyRef?: JourneyRefStructure;
    lineRef?: LineRefStructure;
    mode?: ModeStructure;
    occupancy?: DatedJourneyStructure.occupancy;
    operatingDayRef?: OperatingDayRefStructure;
    operatorRef?: OperatorRefStructure;
    originStopPointRef?: StopPointRefStructure;
    originText?: InternationalTextStructure;
    publishedLineName?: InternationalTextStructure;
    routeDescription?: InternationalTextStructure;
    situationFullRef?: Array<SituationFullRefStructure>;
    unplanned?: boolean;
    vehicleRef?: VehicleRefStructure;
    via?: Array<ServiceViaPointStructure>;
};
export namespace DatedJourneyStructure {
    export enum occupancy {
        MANY_SEATS_AVAILABLE = 'MANY_SEATS_AVAILABLE',
        FEW_SEATS_AVAILABLE = 'FEW_SEATS_AVAILABLE',
        NO_SEATS_AVAILABLE = 'NO_SEATS_AVAILABLE',
        STANDING_AVAILABLE = 'STANDING_AVAILABLE',
        FULL = 'FULL',
    }
}

