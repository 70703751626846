import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bullet: {
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    opacity: 0.5,
    width: theme.spacing(1),
    height: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    transition: theme.transitions.create(['opacity']),
  },
  bulletActive: {
    opacity: 1,
  },
}));

type StepPaginationProps = {
  numberOfSteps: number;
  currentStep: number;
};

export const StepPagination = ({ numberOfSteps, currentStep }: StepPaginationProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {[...Array(numberOfSteps)].map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box className={clsx(classes.bullet, index + 1 === currentStep && classes.bulletActive)} key={index} />
      ))}
    </Box>
  );
};
