import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  legend: {
    height: theme.spacing(2),
    backgroundImage:
      'linear-gradient(to right, #808080, #E2E4E4, #BFE8E8, #93CDFA, #7357FE, #0040FF, #1C0786, #840786, #F300BA)',
    marginBottom: theme.spacing(1.5),
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: '0.8rem',
    color: theme.palette.grey[600],
  },
}));

export const RainRadarLegend = () => {
  const classes = useStyles();

  return (
    <>
      <Typography gutterBottom>Legende</Typography>
      <Box className={classes.legend}> </Box>
      <Box className={classes.labels}>
        {['Gering', 'Mäßig', 'Stark'].map((label) => (
          <Typography key={label} className={classes.label}>
            {label}
          </Typography>
        ))}
      </Box>
    </>
  );
};
