import React from 'react';
import { Box, Button, Link as HyperLink } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

export const footerLinks = [
  { title: 'Zur Radlkarte', to: '/' },
  { title: 'Über das Projekt', to: '/ueber-das-projekt' },
  { title: 'Kontakt', to: '/kontakt' },
  { title: 'Datenschutz', to: '/datenschutz' },
  { title: 'Impressum', href: 'https://www.stadt-salzburg.at/impressum/' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    gridArea: 'footer',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  rootBottom: {
    height: theme.spacing(12),
    marginTop: 'auto',
    flexShrink: 0,
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  items: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
  },
  link: {
    textTransform: 'none',
    fontSize: '0.75rem',
    padding: '6px 8px',
    color: theme.palette.text.primary,
  },
}));

type FooterProps = {
  align?: 'center' | 'bottom';
};

export const Footer = ({ align = 'center' }: FooterProps) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Box component="footer" className={clsx(classes.root, align === 'bottom' && classes.rootBottom)}>
      <Box component="nav" className={classes.wrapper}>
        <Box component="ul" className={classes.items}>
          {footerLinks
            .filter(({ to }) => location.pathname !== '/' || to !== '/')
            .map(({ title, to, href }) => (
              <Box component="li" key={to || href}>
                {href && (
                  <Button
                    variant="text"
                    className={classes.link}
                    component={HyperLink}
                    href={href}
                    underline="none"
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    {title}
                  </Button>
                )}
                {to && (
                  <Button className={classes.link} component={Link} to={to}>
                    {title}
                  </Button>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
