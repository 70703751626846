import padStart from 'lodash/padStart';
import mapboxgl from 'mapbox-gl';

import { MapHelperService } from './MapHelperService';

export class MapRainRadarService {
  private static id = 'rain-radar';

  public static create(map: mapboxgl.Map, visible: boolean, offset: number) {
    map.addSource(MapRainRadarService.id, {
      type: 'image',
      url: MapRainRadarService.getRainRadarUrl(offset),
      coordinates: [
        [12.176, 48.187],
        [13.75, 48.192],
        [13.665, 47.401],
        [12.207, 47.395],
      ],
    });
    map.addLayer({
      id: MapRainRadarService.id,
      source: MapRainRadarService.id,
      type: 'raster',
      layout: { visibility: MapHelperService.getVisibility(visible) },
      paint: {
        'raster-fade-duration': 0,
        'raster-opacity': 0.5,
      },
    });
  }

  public static update(map: mapboxgl.Map, visible: boolean, offset: number) {
    const source = map.getSource(MapRainRadarService.id);

    if (map.isStyleLoaded() && source?.type === 'image') {
      source.updateImage({ url: MapRainRadarService.getRainRadarUrl(offset) });
      map.setLayoutProperty(MapRainRadarService.id, 'visibility', MapHelperService.getVisibility(visible));
    }
  }

  private static getRainRadarUrl = (offset: number) =>
    `${process.env.REACT_APP_RAIN_RADAR_URL}/${offset < 0 ? '-' : ''}${padStart(
      Math.abs(offset).toString(),
      3,
      '0',
    )}.png`;
}
