import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(9),

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

type RoutingHeadingProps = { children: ReactNode };

export const RoutingHeading = ({ children }: RoutingHeadingProps) => {
  const classes = useStyles();

  return (
    <Typography variant="h1" className={classes.root}>
      {children}
    </Typography>
  );
};
