/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BoardingStructure = {
    arrivalBoardingActivity?: BoardingStructure.arrivalBoardingActivity;
    departureBoardingActivity?: BoardingStructure.departureBoardingActivity;
};
export namespace BoardingStructure {
    export enum arrivalBoardingActivity {
        ALIGHTING = 'ALIGHTING',
        NO_ALIGHTING = 'NO_ALIGHTING',
        PASS_THRU = 'PASS_THRU',
    }
    export enum departureBoardingActivity {
        BOARDING = 'BOARDING',
        NO_BOARDING = 'NO_BOARDING',
        PASS_THRU = 'PASS_THRU',
    }
}

