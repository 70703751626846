/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NaturalLanguagePlaceNameStructure } from './NaturalLanguagePlaceNameStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
export type EasementsStructure = {
    easement?: NaturalLanguageStringStructure;
    easementRef?: NaturalLanguagePlaceNameStructure;
    ticketRestrictions?: EasementsStructure.ticketRestrictions;
};
export namespace EasementsStructure {
    export enum ticketRestrictions {
        PTI_25_0 = 'PTI_25_0',
        UNKNOWN = 'UNKNOWN',
        PTI_25_1 = 'PTI_25_1',
        ALL_TICKET_CLASSES_VALID = 'ALL_TICKET_CLASSES_VALID',
        PTI_25_2 = 'PTI_25_2',
        FULL_FARE_ONLY = 'FULL_FARE_ONLY',
        PTI_25_3 = 'PTI_25_3',
        CERTAIN_TICKETS_ONLY = 'CERTAIN_TICKETS_ONLY',
        PTI_25_4 = 'PTI_25_4',
        TICKET_WITH_RESERVATION = 'TICKET_WITH_RESERVATION',
        PTI_25_5 = 'PTI_25_5',
        SPECIAL_FARE = 'SPECIAL_FARE',
        PTI_25_6 = 'PTI_25_6',
        ONLY_TICKETS_OF_SPECIFIED_OPERATOR = 'ONLY_TICKETS_OF_SPECIFIED_OPERATOR',
        PTI_25_7 = 'PTI_25_7',
        NO_RESTRICTIONS = 'NO_RESTRICTIONS',
        PTI_25_8 = 'PTI_25_8',
        NO_OFF_PEAK_TICKETS = 'NO_OFF_PEAK_TICKETS',
        PTI_25_9 = 'PTI_25_9',
        NO_WEEKEND_RETURN_TICKETS = 'NO_WEEKEND_RETURN_TICKETS',
        PTI_25_10 = 'PTI_25_10',
        NO_REDUCED_FARE_TICKETS = 'NO_REDUCED_FARE_TICKETS',
        PTI_25_255 = 'PTI_25_255',
        UNKNOWN_TICKET_RESTRICTION = 'UNKNOWN_TICKET_RESTRICTION',
    }
}

