import React, { ReactChild } from 'react';
import { Box, Typography } from '@mui/material';

type CustomSliderWrapperProps = { children: ReactChild; label: string };

export const CustomSliderWrapper = ({ children, label }: CustomSliderWrapperProps) => (
  <Box>
    <Typography gutterBottom>{label}</Typography>
    {children}
  </Box>
);
