import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';

const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development';
const release =
  process.env.REACT_APP_SENTRY_RELEASE_VERSION && `radlkarte-frontend@${process.env.REACT_APP_SENTRY_RELEASE_VERSION}`;

Sentry.init({
  dsn: 'https://007a27a1f26a4beb9ddacb24cf435661@sentry.trafficon.eu/14',
  enabled: environment !== 'development',
  environment,
  ...(release && { release }),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
