import { Palette } from '@mui/material/styles/createPalette';

import { PointsOfInterestType } from '../types/PointsOfInterestType';
import PoiBox from '../assets/icons/map/points-of-interest/box.png';
import PoiServiceShop from '../assets/icons/map/points-of-interest/service-shop.png';
import PoiRent from '../assets/icons/map/points-of-interest/rent.png';
import PoiTubeVendingMachine from '../assets/icons/map/points-of-interest/tube-vending-machine.png';
import PoiSelfService from '../assets/icons/map/points-of-interest/self-service.png';
import PoiCityTrainStation from '../assets/icons/map/points-of-interest/city-train-station.png';

export class PointsOfInterestService {
  public static typeToImage(type: PointsOfInterestType) {
    return {
      [PointsOfInterestType.BOX]: PoiBox,
      [PointsOfInterestType.SERVICE]: PoiServiceShop,
      [PointsOfInterestType.SHOP_SERVICE]: PoiServiceShop,
      [PointsOfInterestType.RENT]: PoiRent,
      [PointsOfInterestType.TUBE_VENDING_MACHINE]: PoiTubeVendingMachine,
      [PointsOfInterestType.SELF_SERVICE]: PoiSelfService,
      [PointsOfInterestType.CITY_TRAIN_STATION]: PoiCityTrainStation,
    }[type];
  }

  public static typeToColor(type: PointsOfInterestType): keyof Palette['pointsOfInterest'] {
    return {
      [PointsOfInterestType.BOX]: 'teal' as const,
      [PointsOfInterestType.SERVICE]: 'pink' as const,
      [PointsOfInterestType.SHOP_SERVICE]: 'pink' as const,
      [PointsOfInterestType.RENT]: 'pink' as const,
      [PointsOfInterestType.TUBE_VENDING_MACHINE]: 'purple' as const,
      [PointsOfInterestType.SELF_SERVICE]: 'gold' as const,
      [PointsOfInterestType.CITY_TRAIN_STATION]: 'blue' as const,
    }[type];
  }
}
