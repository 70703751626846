import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';
import { BrowserRouter } from 'react-router-dom';

import './setup/sentry';
import './setup/global-state';
import './setup/api';
import './setup/mapbox';

import { App } from './App';
import { theme } from './setup/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
