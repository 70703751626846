/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RoadFilterStructure = {
    directionBound?: RoadFilterStructure.directionBound;
    referencePointIdentifier?: string;
    roadNumber?: string;
};
export namespace RoadFilterStructure {
    export enum directionBound {
        ANTICLOCKWISE = 'ANTICLOCKWISE',
        CLOCKWISE = 'CLOCKWISE',
        NORTH_BOUND = 'NORTH_BOUND',
        NORTH_EAST_BOUND = 'NORTH_EAST_BOUND',
        EAST_BOUND = 'EAST_BOUND',
        SOUTH_EAST_BOUND = 'SOUTH_EAST_BOUND',
        SOUTH_BOUND = 'SOUTH_BOUND',
        SOUTH_WEST_BOUND = 'SOUTH_WEST_BOUND',
        WEST_BOUND = 'WEST_BOUND',
        NORTH_WEST_BOUND = 'NORTH_WEST_BOUND',
    }
}

