/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Delays } from './Delays';
import type { ExtensionType } from './ExtensionType';
import type { ImpactDetails } from './ImpactDetails';
export type Impact = {
    delays?: Delays;
    impactDetails?: ImpactDetails;
    impactExtension?: ExtensionType;
    impactOnTraffic?: Impact.impactOnTraffic;
};
export namespace Impact {
    export enum impactOnTraffic {
        IMPOSSIBLE = 'IMPOSSIBLE',
        CONGESTED = 'CONGESTED',
        HEAVY = 'HEAVY',
        FREE_FLOW = 'FREE_FLOW',
        UNKNOWN = 'UNKNOWN',
    }
}

