import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useThrottle } from 'react-use';

import { GeocoderService } from '../services/api-geocoding';
import { GeocoderFeatureService } from '../services/GeocoderFeatureService';
import { PlaceType } from '../types/PlaceType';
import { CurrentGeolocationService } from '../services/CurrentGeolocationService';
import { useStore } from '../setup/global-state';
import { ReactComponent as MarkerOrigin } from '../assets/icons/forms/marker-origin.svg';
import { ReactComponent as MarkerDestination } from '../assets/icons/forms/marker-destination.svg';
import { ReactComponent as FillInIcon } from '../assets/icons/forms/fill-in.svg';
import { Place } from '../types/Place';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  autocompleteRoot: {
    marginLeft: theme.spacing(-6),
    backgroundColor: theme.palette.common.white,

    '& .MuiFormLabel-root': {
      marginLeft: theme.spacing(6),
    },
  },
  inputRoot: {
    paddingRight: '0 !important',

    '&::before': {
      borderBottomColor: theme.palette.secondary.main,
      borderBottomWidth: 2,
    },

    '&:hover::before': {
      borderBottomColor: '#555555 !important',
    },
  },
  input: {
    paddingLeft: `${theme.spacing(6)} !important`,
    height: theme.spacing(3.25),
  },
  noOptions: {
    display: ({ showNoOptions }: { showNoOptions: boolean; hasValue: boolean }) => (showNoOptions ? 'block' : 'none'),
  },
  paper: {
    boxShadow: 'none',
    backgroundColor: '#efefef',
    borderRadius: 0,
    marginTop: 0,
  },
  option: {
    fontSize: '1rem',
  },
  optionSubtitle: {
    fontSize: '0.75rem',
    lineHeight: 1.1,
  },
  fillInButton: {
    color: '#c7c7c7',
    borderRadius: 0,

    '.MuiAutocomplete-popper[x-placement="bottom"] & svg': {
      transform: 'rotate(90deg)',
    },
  },
  markerButton: {
    zIndex: 100,
  },
  markerIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: ({ hasValue }: { showNoOptions: boolean; hasValue: boolean }) =>
      hasValue ? theme.palette.primary.main : theme.palette.secondary.main,
  },
  mobileFocus: {
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      zIndex: 1200,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: '#8D8D8D',
      padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
      marginBottom: '0 !important',

      '& .MuiAutocomplete-inputRoot': {
        marginTop: 0,
      },
      '& .MuiAutocomplete-input': {
        height: theme.spacing(5),
      },
      '& .MuiFormLabel-root': {
        display: 'none',
      },

      '& $markerButton': {
        zIndex: 1300,
      },
    },
  },
}));

type GeocodingSearchInputProps = {
  label: string;
  placeType: PlaceType;
};

export const GeocodingSearchInput = ({ label, placeType }: GeocodingSearchInputProps) => {
  const value = useStore((state) => state.places[placeType]);
  const updatePlace = useStore((state) => state.updatePlace);

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [highlight, setHighlight] = useState<Place | null>(null);
  const throttledInputValue = useThrottle(inputValue, 300);
  const [focus, setFocus] = useState(false);
  const [options, setOptions] = useState<Place[]>([]);
  const classes = useStyles({
    showNoOptions: !loading && inputValue !== '' && inputValue !== value?.name.full,
    hasValue: !!value,
  });
  const mapCenter = useStore((state) => state.mapCenter);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    let componentMounted = true;

    if (focus && throttledInputValue !== '') {
      (async () => {
        const places = (
          (await GeocoderService.geocoding({ q: throttledInputValue, lon: mapCenter.lng, lat: mapCenter.lat }))
            ?.features || []
        ).map((feature) => GeocoderFeatureService.transformToPlace(feature));

        if (componentMounted) {
          setOptions(places);
        }
      })();
    }

    return () => {
      componentMounted = false;
    };
  }, [value, throttledInputValue, focus, mapCenter.lng, mapCenter.lat]);

  const handleCurrentGeolocation = async () => {
    setLoading(true);
    const geolocationPosition = await CurrentGeolocationService.getPosition({
      enableHighAccuracy: true,
    });
    const position = {
      lat: geolocationPosition.coords.latitude,
      lng: geolocationPosition.coords.longitude,
    };
    const reverseGeocoding = await GeocoderService.reverse({ lat: position.lat, lon: position.lng });

    const name = reverseGeocoding.features?.[0]
      ? GeocoderFeatureService.getNameFromFeature(reverseGeocoding.features[0])
      : { full: 'Aktuelle Position', firstLine: 'Aktuelle Position' };

    updatePlace({ placeType, place: { name, ...position } });

    setLoading(false);
  };

  const handleChange = (newPlace: Place | null) => {
    setFocus(!newPlace);
    updatePlace({
      placeType,
      place: newPlace || undefined,
    });
  };

  const optionsIncludeValue = value && options.find((option) => GeocoderFeatureService.areSamePlaces(option, value));
  const autocompleteOptions = [...options];

  if (value && !optionsIncludeValue) {
    autocompleteOptions.unshift(value);
  }

  useEffect(() => {
    const handleResize = () => window.scrollTo({ top: 0 });
    window.visualViewport?.addEventListener('resize', handleResize);

    return () => window.visualViewport?.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box className={clsx(classes.root, focus && classes.mobileFocus)}>
      <Grid container alignItems="flex-end">
        <Grid item>
          <IconButton
            className={classes.markerButton}
            onClick={handleCurrentGeolocation}
            title={
              {
                [PlaceType.ORIGIN]: 'Mein Standort als Start verwenden',
                [PlaceType.DESTINATION]: 'Mein Standort als Ziel verwenden',
              }[placeType]
            }
            size="large"
          >
            {!loading && placeType === PlaceType.ORIGIN && <MarkerOrigin className={classes.markerIcon} />}
            {!loading && placeType === PlaceType.DESTINATION && <MarkerDestination className={classes.markerIcon} />}
            {loading && <CircularProgress size={24} />}
          </IconButton>
        </Grid>
        <Grid item xs>
          <Autocomplete
            classes={{
              root: classes.autocompleteRoot,
              noOptions: classes.noOptions,
              inputRoot: classes.inputRoot,
              input: classes.input,
              paper: classes.paper,
              option: classes.option,
            }}
            getOptionLabel={({ name: { full } }) => full}
            isOptionEqualToValue={GeocoderFeatureService.areSamePlaces}
            options={autocompleteOptions}
            autoComplete
            blurOnSelect
            includeInputInList
            filterSelectedOptions={!optionsIncludeValue}
            filterOptions={(x) => x}
            forcePopupIcon={false}
            defaultValue={null}
            value={value || null}
            onChange={(event, newPlace) => handleChange(newPlace)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onHighlightChange={(event, option) => {
              setHighlight(option);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && event.currentTarget.querySelector('input') === document.activeElement) {
                handleChange(options[0]);
                event.currentTarget.querySelector('input')?.blur();
              }
              if (event.key === 'Tab') {
                const place = highlight || options[0];

                if (place && inputValue !== place.name.firstLine) {
                  event.preventDefault();
                  setInputValue(place.name.firstLine);
                }
              }
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null,
                }}
                label={label}
                fullWidth
              />
            )}
            renderOption={(props, place) => {
              const {
                name: { firstLine, secondLine },
              } = place;

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      {firstLine}
                      {secondLine && <Box className={classes.optionSubtitle}>{secondLine}</Box>}
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        className={classes.fillInButton}
                        onClick={(event) => {
                          event.stopPropagation();

                          setInputValue(place.name.firstLine);
                        }}
                      >
                        <FillInIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
