/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Trias } from '../models/Trias';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RolltApiControllerService {
    /**
     * Spring server's default entry point.
     * It works.
     * @returns string OK
     * @throws ApiError
     */
    public static springStartUsingGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns a sample trip request Use Case EIGHT:</br>BIKE route request origin to destination WITHOUT public transport.
     * Use Case EIGHT - DEPARTURE - BIKE route request origin to destination WITHOUT public transport.
     * @returns Trias OK
     * @throws ApiError
     */
    public static dummyRequest8UsingGet(): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dummyRequest8',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns a sample trip request Use Case EIGHT - ARRIVAL:</br>BIKE route request origin to destination WITHOUT public transport.
     * Use Case EIGHT - ARRIVAL - Public transport request ('TAKE MY BIKE WITH ME') AND bike route request origin to destination WITHOUT public transport.
     * @returns Trias OK
     * @throws ApiError
     */
    public static dummyRequest8ArrivalUsingGet(): CancelablePromise<Trias> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/dummyRequest8Arrival',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Checks if both the connection and routing requests to the connected public transport service are working
     * YES...connection and routing are working, NO...not
     * @returns string OK
     * @throws ApiError
     */
    public static isConnectionToPublicTransportServiceWorkingUsingGet({
        requestHeader,
    }: {
        /**
         * requestHeader
         */
        requestHeader: any,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isConnectionToPublicTransportServiceWorking',
            headers: {
                'requestHeader': requestHeader,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Checks if the servlet's connection to the IV routing system is at least working (without route calculation)
     * YES...servlet's connection to the IV routing system, NO...not
     * @returns string OK
     * @throws ApiError
     */
    public static isConnectionToTrafficonRouterWorkingUsingGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isConnectionToTrafficonRouterWorking',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Checks if both the connection and routing requests to Trafficons IV routing core are working
     * YES...connection and routing are working, NO...not
     * @returns string OK
     * @throws ApiError
     */
    public static isConnectionToTrafficonRouterWorkingAndIsRoutingWorkingUsingGet({
        requestHeader,
        caller,
    }: {
        /**
         * requestHeader
         */
        requestHeader: any,
        /**
         * caller
         */
        caller?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isConnectionToTrafficonRouterWorkingAndIsRoutingWorking',
            headers: {
                'requestHeader': requestHeader,
            },
            query: {
                'caller': caller,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Checks if the servlet is at least working
     * YES...servlet is working, NO...not
     * @returns string OK
     * @throws ApiError
     */
    public static isImlServletWorkingUsingGet(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isIMLServletWorking',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Accepts TRIAS routing request and returns XML (TRIAS) response
     * Accepts a 'special' time interval-based request of a TRIAS service IndividualRouteRequest including TraffiCon extensions.</br>Returns a CSV String entity holding indikatoren values.
     * @returns Trias OK
     * @returns any Created
     * @throws ApiError
     */
    public static requestRouteTriasUsingPost({
        requestHeader,
        triasRequest,
    }: {
        /**
         * requestHeader
         */
        requestHeader: any,
        /**
         * triasRequest
         */
        triasRequest: Trias,
    }): CancelablePromise<Trias | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-route-trias',
            headers: {
                'requestHeader': requestHeader,
            },
            body: triasRequest,
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
