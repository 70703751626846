/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AffectedConnectionLinkStructure } from './AffectedConnectionLinkStructure';
import type { DatedVehicleJourneyRefStructure } from './DatedVehicleJourneyRefStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { InterchangeRefStructure } from './InterchangeRefStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
export type AffectedInterchangeStructure = {
    connectingVehicleJourneyRef?: DatedVehicleJourneyRefStructure;
    connectionLink?: Array<AffectedConnectionLinkStructure>;
    extensions?: ExtensionsStructure;
    interchangeRef?: InterchangeRefStructure;
    interchangeStatusType?: AffectedInterchangeStructure.interchangeStatusType;
    interchangeStopPointName?: NaturalLanguageStringStructure;
    interchangeStopPointRef?: StopPointRefStructure;
};
export namespace AffectedInterchangeStructure {
    export enum interchangeStatusType {
        PTI_31_0 = 'PTI_31_0',
        UNKNOWN = 'UNKNOWN',
        PTI_31_1 = 'PTI_31_1',
        CONNECTION = 'CONNECTION',
        PTI_31_2 = 'PTI_31_2',
        REPLACEMENT = 'REPLACEMENT',
        PTI_31_3 = 'PTI_31_3',
        ALTERNATIVE = 'ALTERNATIVE',
        PTI_31_4 = 'PTI_31_4',
        CONNECTION_NOT_HELD = 'CONNECTION_NOT_HELD',
        PTI_31_5 = 'PTI_31_5',
        CONNECTION_HELD = 'CONNECTION_HELD',
        PTI_31_6 = 'PTI_31_6',
        STATUS_OF_CONENCTION_UNDECIDED = 'STATUS_OF_CONENCTION_UNDECIDED',
        PTI_31_255 = 'PTI_31_255',
        UNDEFINED_CROSS_REFERENCE_INFORMATION = 'UNDEFINED_CROSS_REFERENCE_INFORMATION',
    }
}

