import React from 'react';
import { Box, IconButton, Link, Typography } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import { makeStyles } from '@mui/styles';

import { PointsOfInterestType } from '../types/PointsOfInterestType';
import { PointsOfInterestService } from '../services/PointsOfInterestService';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';

const getProperty = (properties: Record<string, string>, field: string) => {
  if (properties[field] && properties[field] !== 'null') {
    return properties[field];
  }

  return false;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[1],
    backgroundColor: ({ type }: { type: PointsOfInterestType }) =>
      theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].main,
  },

  '@global': {
    '.mapboxgl-popup-tip': {
      '.point-of-interest-pop-up.mapboxgl-popup-anchor-top &, .point-of-interest-pop-up.mapboxgl-popup-anchor-top-left &, .point-of-interest-pop-up.mapboxgl-popup-anchor-top-right &':
        {
          borderBottomColor: ({ type }: { type: PointsOfInterestType }) =>
            theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].main,
        },
      '.point-of-interest-pop-up.mapboxgl-popup-anchor-bottom &, .point-of-interest-pop-up.mapboxgl-popup-anchor-bottom-left &, .point-of-interest-pop-up.mapboxgl-popup-anchor-bottom-right &':
        {
          borderTopColor: ({ type }: { type: PointsOfInterestType }) =>
            theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].main,
        },
      '.point-of-interest-pop-up.mapboxgl-popup-anchor-left &': {
        borderRightColor: ({ type }: { type: PointsOfInterestType }) =>
          theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].main,
      },
      '.point-of-interest-pop-up.mapboxgl-popup-anchor-right &': {
        borderLeftColor: ({ type }: { type: PointsOfInterestType }) =>
          theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].main,
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  closeIcon: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  type: {
    fontSize: '0.75rem',
    color: ({ type }: { type: PointsOfInterestType }) =>
      theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].contrastText,

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  name: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,

    '&:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  address: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    color: ({ type }: { type: PointsOfInterestType }) =>
      theme.palette.pointsOfInterest[PointsOfInterestService.typeToColor(type)].contrastText,
    textDecoration: 'underline',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

type PointOfInterestPopUpProps = {
  feature: mapboxgl.MapboxGeoJSONFeature;
  onClose: () => void;
};

export const PointOfInterestPopUp = ({ feature, onClose }: PointOfInterestPopUpProps) => {
  const classes = useStyles({
    type: (feature.properties?.Typ
      ? feature.properties.Typ
      : PointsOfInterestType.CITY_TRAIN_STATION) as PointsOfInterestType,
  });

  return feature.properties ? (
    <Box className={classes.root}>
      <IconButton aria-label="Schließen" className={classes.closeButton} onClick={onClose} size="large">
        <CloseIcon className={classes.closeIcon} />
      </IconButton>

      {(getProperty(feature.properties, 'Typ') || getProperty(feature.properties, 'Haltestellenname')) && (
        <Typography className={classes.type}>
          {getProperty(feature.properties, 'Typ') || PointsOfInterestType.CITY_TRAIN_STATION}
        </Typography>
      )}
      {(getProperty(feature.properties, 'Name') || getProperty(feature.properties, 'Haltestellenname')) && (
        <Typography className={classes.name} component="h4">
          {getProperty(feature.properties, 'Name') || getProperty(feature.properties, 'Haltestellenname')}
        </Typography>
      )}
      {getProperty(feature.properties, 'Strasse') && (
        <Typography className={classes.address}>
          {getProperty(feature.properties, 'Strasse')} {getProperty(feature.properties, 'Hausnr')}{' '}
          {getProperty(feature.properties, 'Hausnr_Zusatz')}
          <br />
          {getProperty(feature.properties, 'PLZ')} {getProperty(feature.properties, 'Ort')}
        </Typography>
      )}
      {getProperty(feature.properties, 'TelNr') && (
        <Link className={classes.link} href={`tel:${getProperty(feature.properties, 'TelNr')}`} display="block">
          {getProperty(feature.properties, 'TelNr')}
        </Link>
      )}
      {getProperty(feature.properties, 'Homepage') && (
        <Link
          className={classes.link}
          href={getProperty(feature.properties, 'Homepage') as string}
          target="_blank"
          rel="noreferrer"
          display="block"
        >
          Website
        </Link>
      )}
    </Box>
  ) : null;
};
