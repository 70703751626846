/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AffectedRoadsStructure } from './AffectedRoadsStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { Networks } from './Networks';
import type { Operators } from './Operators';
import type { Places } from './Places';
import type { StopPlaces } from './StopPlaces';
import type { StopPoints } from './StopPoints';
import type { VehicleJourneys } from './VehicleJourneys';
export type AffectsScopeStructure = {
    areaOfInterest?: AffectsScopeStructure.areaOfInterest;
    extensions?: ExtensionsStructure;
    networks?: Networks;
    operators?: Operators;
    places?: Places;
    roads?: AffectedRoadsStructure;
    stopPlaces?: StopPlaces;
    stopPoints?: StopPoints;
    vehicleJourneys?: VehicleJourneys;
};
export namespace AffectsScopeStructure {
    export enum areaOfInterest {
        CONTINENT_WIDE = 'CONTINENT_WIDE',
        NATIONAL = 'NATIONAL',
        NEIGHBOURING_COUNTRIES = 'NEIGHBOURING_COUNTRIES',
        NOT_SPECIFIED = 'NOT_SPECIFIED',
        REGIONAL = 'REGIONAL',
    }
}

