import { Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ContentPageWrapper } from '../components/ContentPageWrapper';

const useStyles = makeStyles(() => ({
  about: {
    maxWidth: 600,
  },
}));

export const AboutPage = () => {
  const classes = useStyles();

  return (
    <ContentPageWrapper>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item className={classes.about}>
          <Typography gutterBottom variant="h2">
            Über das Projekt
          </Typography>
          <Typography gutterBottom>
            Stadt und Land Salzburg betreiben für alle Radfahrenden den Webservice Radlkarte.info. Dieser innovative
            Radrouter zeichnet sich durch die Berechnung und Darstellung einer „empfohlenen Radroute“ aus, die
            Sicherheitsaspekte und Schnelligkeit vereint.
          </Typography>
          <Typography gutterBottom>
            Die Radlkarte beschränkt sich auf das Bundesland Salzburg, bezieht aber auch vier bayrische Nachbargemeinden
            (Freilassing, Ainring, Piding, Saaldorf-Surheim) ein, um ein grenzüberschreitendes Radrouting zu
            ermöglichen.
          </Typography>
          <Typography gutterBottom>
            Das Service ist als Innovations- und Entwicklungsplattform gedacht, die interessierten NutzerInnen neue
            Technologien und Funktionen im Sinne eines Reallabors zur Verfügung stellt. Diese Version kann zum Beispiel
            vom Smartphone bis zum klassischen PC genutzt werden, ohne ein eigenes Programm installieren zu müssen.
          </Typography>
          <Typography gutterBottom>
            Für die technische Umsetzung ist die Trafficon - Traffic Consultants GmbH mit Sitz in Salzburg
            verantwortlich. Gewisse methodische Ansätze beruhen auf Forschungsarbeiten des Mobility Labs am Fachbereich
            Geoinformatik der Universität Salzburg.
          </Typography>
          <Typography gutterBottom>
            Aufgrund des hohen Innovationsgrades können wir nicht garantieren, dass alle Routingvorschläge perfekte
            Ergebnisse liefern. Es gilt unbedingt die örtlichen Gegebenheiten und die Straßenverkehrsordnung zu
            berücksichtigen.
          </Typography>
          <Typography gutterBottom>
            Wir freuen uns auf Feedback und nehmen Verbesserungsvorschläge gerne entgegen. Schreib uns einfach ein
            E-Mail <Link href="mailto:office@salzburgrad.at">office@salzburgrad.at</Link>.
          </Typography>
          <Typography gutterBottom>Viel Spaß beim Radln!</Typography>
          <Typography gutterBottom>Eurer Radteam von Stadt und Land</Typography>
        </Grid>
      </Grid>
    </ContentPageWrapper>
  );
};
