import React from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

import { ContentPageWrapper } from '../components/ContentPageWrapper';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <ContentPageWrapper containerClassName={classes.root}>
      <>
        <Typography align="center" variant="h1" gutterBottom>
          Seite nicht gefunden
        </Typography>
        <Button color="primary" component={Link} to="/">
          Zur Startseite
        </Button>
      </>
    </ContentPageWrapper>
  );
};
