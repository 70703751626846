/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InternationalTextStructure } from './InternationalTextStructure';
export type ModeStructure = {
    airSubmode?: ModeStructure.airSubmode;
    busSubmode?: ModeStructure.busSubmode;
    coachSubmode?: ModeStructure.coachSubmode;
    description?: InternationalTextStructure;
    funicularSubmode?: ModeStructure.funicularSubmode;
    metroSubmode?: ModeStructure.metroSubmode;
    name?: InternationalTextStructure;
    ptMode?: ModeStructure.ptMode;
    railSubmode?: ModeStructure.railSubmode;
    shortName?: InternationalTextStructure;
    telecabinSubmode?: ModeStructure.telecabinSubmode;
    tramSubmode?: ModeStructure.tramSubmode;
    waterSubmode?: ModeStructure.waterSubmode;
};
export namespace ModeStructure {
    export enum airSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        INTERNATIONAL_FLIGHT = 'INTERNATIONAL_FLIGHT',
        DOMESTIC_FLIGHT = 'DOMESTIC_FLIGHT',
        INTERCONTINENTAL_FLIGHT = 'INTERCONTINENTAL_FLIGHT',
        DOMESTIC_SCHEDULED_FLIGHT = 'DOMESTIC_SCHEDULED_FLIGHT',
        SHUTTLE_FLIGHT = 'SHUTTLE_FLIGHT',
        INTERCONTINENTAL_CHARTER_FLIGHT = 'INTERCONTINENTAL_CHARTER_FLIGHT',
        INTERNATIONAL_CHARTER_FLIGHT = 'INTERNATIONAL_CHARTER_FLIGHT',
        ROUND_TRIP_CHARTER_FLIGHT = 'ROUND_TRIP_CHARTER_FLIGHT',
        SIGHTSEEING_FLIGHT = 'SIGHTSEEING_FLIGHT',
        HELICOPTER_SERVICE = 'HELICOPTER_SERVICE',
        DOMESTIC_CHARTER_FLIGHT = 'DOMESTIC_CHARTER_FLIGHT',
        SCHENGEN_AREA_FLIGHT = 'SCHENGEN_AREA_FLIGHT',
        AIRSHIP_SERVICE = 'AIRSHIP_SERVICE',
        SHORT_HAUL_INTERNATIONAL_FLIGHT = 'SHORT_HAUL_INTERNATIONAL_FLIGHT',
        CANAL_BARGE = 'CANAL_BARGE',
    }
    export enum busSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        LOCAL_BUS = 'LOCAL_BUS',
        REGIONAL_BUS = 'REGIONAL_BUS',
        EXPRESS_BUS = 'EXPRESS_BUS',
        NIGHT_BUS = 'NIGHT_BUS',
        POST_BUS = 'POST_BUS',
        SPECIAL_NEEDS_BUS = 'SPECIAL_NEEDS_BUS',
        MOBILITY_BUS = 'MOBILITY_BUS',
        MOBILITY_BUS_FOR_REGISTERED_DISABLED = 'MOBILITY_BUS_FOR_REGISTERED_DISABLED',
        SIGHTSEEING_BUS = 'SIGHTSEEING_BUS',
        SHUTTLE_BUS = 'SHUTTLE_BUS',
        SCHOOL_BUS = 'SCHOOL_BUS',
        SCHOOL_AND_PUBLIC_SERVICE_BUS = 'SCHOOL_AND_PUBLIC_SERVICE_BUS',
        RAIL_REPLACEMENT_BUS = 'RAIL_REPLACEMENT_BUS',
        DEMAND_AND_RESPONSE_BUS = 'DEMAND_AND_RESPONSE_BUS',
        AIRPORT_LINK_BUS = 'AIRPORT_LINK_BUS',
    }
    export enum coachSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        INTERNATIONAL_COACH = 'INTERNATIONAL_COACH',
        NATIONAL_COACH = 'NATIONAL_COACH',
        SHUTTLE_COACH = 'SHUTTLE_COACH',
        REGIONAL_COACH = 'REGIONAL_COACH',
        SPECIAL_COACH = 'SPECIAL_COACH',
        SIGHTSEEING_COACH = 'SIGHTSEEING_COACH',
        TOURIST_COACH = 'TOURIST_COACH',
        COMMUTER_COACH = 'COMMUTER_COACH',
    }
    export enum funicularSubmode {
        UNKNOWN = 'UNKNOWN',
        FUNICULAR = 'FUNICULAR',
        ALL_FUNICULAR_SERVICES = 'ALL_FUNICULAR_SERVICES',
        UNDEFINED_FUNICULAR = 'UNDEFINED_FUNICULAR',
    }
    export enum metroSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        METRO = 'METRO',
        TUBE = 'TUBE',
        URBAN_RAILWAY = 'URBAN_RAILWAY',
    }
    export enum ptMode {
        ALL = 'ALL',
        UNKNOWN = 'UNKNOWN',
        AIR = 'AIR',
        BUS = 'BUS',
        TROLLEY_BUS = 'TROLLEY_BUS',
        TRAM = 'TRAM',
        COACH = 'COACH',
        RAIL = 'RAIL',
        INTERCITY_RAIL = 'INTERCITY_RAIL',
        URBAN_RAIL = 'URBAN_RAIL',
        METRO = 'METRO',
        WATER = 'WATER',
        CABLEWAY = 'CABLEWAY',
        FUNICULAR = 'FUNICULAR',
        TAXI = 'TAXI',
    }
    export enum railSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        LOCAL = 'LOCAL',
        HIGH_SPEED_RAIL = 'HIGH_SPEED_RAIL',
        SUBURBAN_RAILWAY = 'SUBURBAN_RAILWAY',
        REGIONAL_RAIL = 'REGIONAL_RAIL',
        INTERREGIONAL_RAIL = 'INTERREGIONAL_RAIL',
        LONG_DISTANCE = 'LONG_DISTANCE',
        INTERNATIONAL = 'INTERNATIONAL',
        SLEEPER_RAIL_SERVICE = 'SLEEPER_RAIL_SERVICE',
        NIGHT_RAIL = 'NIGHT_RAIL',
        CAR_TRANSPORT_RAIL_SERVICE = 'CAR_TRANSPORT_RAIL_SERVICE',
        TOURIST_RAILWAY = 'TOURIST_RAILWAY',
        RAIL_SHUTTLE = 'RAIL_SHUTTLE',
        REPLACEMENT_RAIL_SERVICE = 'REPLACEMENT_RAIL_SERVICE',
        SPECIAL_TRAIN = 'SPECIAL_TRAIN',
        CROSS_COUNTRY_RAIL = 'CROSS_COUNTRY_RAIL',
        RACK_AND_PINION_RAILWAY = 'RACK_AND_PINION_RAILWAY',
    }
    export enum telecabinSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        TELECABIN = 'TELECABIN',
        CABLE_CAR = 'CABLE_CAR',
        LIFT = 'LIFT',
        CHAIR_LIFT = 'CHAIR_LIFT',
        DRAG_LIFT = 'DRAG_LIFT',
        TELECABIN_LINK = 'TELECABIN_LINK',
    }
    export enum tramSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        CITY_TRAM = 'CITY_TRAM',
        LOCAL_TRAM = 'LOCAL_TRAM',
        REGIONAL_TRAM = 'REGIONAL_TRAM',
        SIGHTSEEING_TRAM = 'SIGHTSEEING_TRAM',
        SHUTTLE_TRAM = 'SHUTTLE_TRAM',
    }
    export enum waterSubmode {
        UNKNOWN = 'UNKNOWN',
        UNDEFINED = 'UNDEFINED',
        INTERNATIONAL_CAR_FERRY = 'INTERNATIONAL_CAR_FERRY',
        NATIONAL_CAR_FERRY = 'NATIONAL_CAR_FERRY',
        REGIONAL_CAR_FERRY = 'REGIONAL_CAR_FERRY',
        LOCAL_CAR_FERRY = 'LOCAL_CAR_FERRY',
        INTERNATIONAL_PASSENGER_FERRY = 'INTERNATIONAL_PASSENGER_FERRY',
        NATIONAL_PASSENGER_FERRY = 'NATIONAL_PASSENGER_FERRY',
        REGIONAL_PASSENGER_FERRY = 'REGIONAL_PASSENGER_FERRY',
        LOCAL_PASSENGER_FERRY = 'LOCAL_PASSENGER_FERRY',
        POST_BOAT = 'POST_BOAT',
        TRAIN_FERRY = 'TRAIN_FERRY',
        ROAD_FERRY_LINK = 'ROAD_FERRY_LINK',
        AIRPORT_BOAT_LINK = 'AIRPORT_BOAT_LINK',
        HIGH_SPEED_VEHICLE_SERVICE = 'HIGH_SPEED_VEHICLE_SERVICE',
        HIGH_SPEED_PASSENGER_SERVICE = 'HIGH_SPEED_PASSENGER_SERVICE',
        SIGHTSEEING_SERVICE = 'SIGHTSEEING_SERVICE',
        SCHOOL_BOAT = 'SCHOOL_BOAT',
        CABLE_FERRY = 'CABLE_FERRY',
        RIVER_BUS = 'RIVER_BUS',
        SCHEDULED_FERRY = 'SCHEDULED_FERRY',
        SHUTTLE_FERRY_SERVICE = 'SHUTTLE_FERRY_SERVICE',
    }
}

