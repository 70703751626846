import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Fade, Paper, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { CustomIconButton } from './CustomIconButton';

const useStyles = makeStyles((theme) => ({
  example: {
    marginTop: theme.spacing(2),
  },
  exampleIcon: {
    display: 'block',
    margin: '0 auto',
  },
  examplePaper: {
    marginTop: theme.spacing(2),
  },
  examplePopper: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2),
    borderRadius: 0,
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: theme.spacing(-2),
      left: `calc(50% - ${theme.spacing(2)})`,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
      borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
    },
  },
}));

type CustomDialogExampleProps = {
  exampleIcon?: ReactNode;
  exampleTitle?: string;
  exampleComponent?: ReactNode;
  examplePopper?: boolean;
};

export const CustomDialogExample = ({
  exampleIcon,
  exampleTitle,
  exampleComponent,
  examplePopper,
}: CustomDialogExampleProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [exampleOpen, setExampleOpen] = useState(false);
  const paper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExampleOpen(false);
  }, [exampleIcon]);

  return matches && exampleIcon && exampleComponent ? (
    <Box className={classes.example}>
      <CustomIconButton
        title={exampleTitle}
        className={classes.exampleIcon}
        onClick={() => setExampleOpen((state) => !state)}
      >
        {exampleIcon}
      </CustomIconButton>
      <Fade in={exampleOpen} unmountOnExit onEnter={() => paper.current?.scrollIntoView({ behavior: 'smooth' })}>
        <Paper
          ref={paper}
          className={clsx(classes.examplePaper, examplePopper && classes.examplePopper)}
          elevation={examplePopper ? 1 : 0}
        >
          {exampleComponent}
        </Paper>
      </Fade>
    </Box>
  ) : null;
};
