import { responsiveFontSizes, createTheme, css } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

import Arek800ItalicWoff from '../assets/fonts/arek/arek-800-italic.woff';
import Arek800ItalicWoff2 from '../assets/fonts/arek/arek-800-italic.woff2';
import NewFrank400NormalWoff from '../assets/fonts/new-frank/new-frank-400-normal.woff';
import NewFrank400NormalWoff2 from '../assets/fonts/new-frank/new-frank-400-normal.woff2';
import NewFrank700NormalWoff from '../assets/fonts/new-frank/new-frank-700-normal.woff';
import NewFrank700NormalWoff2 from '../assets/fonts/new-frank/new-frank-700-normal.woff2';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    pointsOfInterest: {
      teal: SimplePaletteColorOptions;
      blue: SimplePaletteColorOptions;
      pink: SimplePaletteColorOptions;
      gold: SimplePaletteColorOptions;
      purple: SimplePaletteColorOptions;
    };
  }

  interface PaletteOptions {
    pointsOfInterest: {
      teal: SimplePaletteColorOptions;
      blue: SimplePaletteColorOptions;
      pink: SimplePaletteColorOptions;
      gold: SimplePaletteColorOptions;
      purple: SimplePaletteColorOptions;
    };
  }
}

export const theme = responsiveFontSizes(
  createTheme(
    {
      palette: {
        primary: {
          main: '#00aeea',
          contrastText: '#ffffff',
        },
        secondary: {
          main: '#dddddd',
          contrastText: '#3a3a3a',
        },

        text: {
          primary: '#3a3a3a',
        },

        pointsOfInterest: {
          teal: { main: '#DCF0F4', contrastText: '#3E8797' },
          blue: { main: '#D3E0ED', contrastText: '#22497C' },
          pink: { main: '#F9D1E6', contrastText: '#AA1B5F' },
          gold: { main: '#FCEFD2', contrastText: '#B58500' },
          purple: { main: '#E0CEE4', contrastText: '#61336B' },
        },
      },
      typography: {
        fontSize: 16,
        fontFamily: ['new-frank', 'sans-serif'].join(','),

        allVariants: {
          fontSize: '1rem',
        },

        h1: {
          fontSize: '2.5rem',
          fontFamily: ['arek', 'serif'].join(','),
          fontWeight: 800,
          fontStyle: 'italic',
        },
        h2: {
          fontSize: '2rem',
          fontFamily: ['arek', 'serif'].join(','),
          fontWeight: 800,
          fontStyle: 'italic',
        },
        h3: {
          fontSize: '1.75rem',
          fontFamily: ['arek', 'serif'].join(','),
          fontWeight: 800,
          fontStyle: 'italic',
        },
        h4: {
          fontSize: '1.5rem',
          fontFamily: ['arek', 'serif'].join(','),
          fontWeight: 800,
          fontStyle: 'italic',
        },
        h5: {
          fontSize: '1.25rem',
          fontFamily: ['arek', 'serif'].join(','),
          fontWeight: 800,
          fontStyle: 'italic',
        },
        h6: {
          fontSize: '1rem',
          fontFamily: ['arek', 'serif'].join(','),
          fontWeight: 800,
          fontStyle: 'italic',
        },
      },
      shape: {
        borderRadius: 4,
      },
      shadows: [
        'none',
        '0 0.2px 0.4px rgba(0, 0, 0, 0.017), 0 0.5px 1.1px rgba(0, 0, 0, 0.024), 0 1px 2px rgba(0, 0, 0, 0.03), 0 1.8px 3.6px rgba(0, 0, 0, 0.036), 0 3.3px 6.7px rgba(0, 0, 0, 0.043), 0 8px 16px rgba(0, 0, 0, 0.06)',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
      ],
      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              fontFamily: ['new-frank', 'sans-serif'].join(','),
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: () => css`
            @font-face {
              font-family: 'arek';
              font-style: italic;
              font-display: swap;
              font-weight: 800;
              src: ${[Arek800ItalicWoff, Arek800ItalicWoff2]
                .map((font, index) => `url(${font}) format('woff${index > 0 ? '2' : ''}')`)
                .join(', ')};
            }

            @font-face {
              font-family: 'new-frank';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: ${[NewFrank400NormalWoff, NewFrank400NormalWoff2]
                .map((font, index) => `url(${font}) format('woff${index > 0 ? '2' : ''}')`)
                .join(', ')};
            }
            @font-face {
              font-family: 'new-frank';
              font-style: normal;
              font-display: swap;
              font-weight: 700;
              src: ${[NewFrank700NormalWoff, NewFrank700NormalWoff2]
                .map((font, index) => `url(${font}) format('woff${index > 0 ? '2' : ''}')`)
                .join(', ')};
            }

            body {
              position: absolute;
              overflow: hidden;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: #ffffff;
            }

            #root {
              height: 100%;
            }

            .mapboxgl-popup .mapboxgl-popup-content {
              padding: 0;
              border-radius: 0;
              box-shadow: none;
            }
          `,
        },
        MuiBackdrop: {
          styleOverrides: {
            root: {
              backdropFilter: 'blur(4px)',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 0,
              padding: '16px 40px',
              fontWeight: 'bold',
            },
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'standard',
          },
        },
        MuiUseMediaQuery: {
          defaultProps: {
            noSsr: true,
          },
        },
      },
    },
    deDE,
  ),
);
