/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisplayContentStructure } from './DisplayContentStructure';
import type { VehicleTypeStructure } from './VehicleTypeStructure';
export type ConnectionStructure = {
    connectionRef?: string;
    connectionState?: ConnectionStructure.connectionState;
    connectionType?: ConnectionStructure.connectionType;
    departureStopPointRef?: string;
    displayContent?: DisplayContentStructure;
    expectedDepartureTime?: string;
    platform?: string;
    transportmode?: VehicleTypeStructure;
};
export namespace ConnectionStructure {
    export enum connectionState {
        CONNECTION_BROKEN = 'CONNECTION_BROKEN',
        CONNECTION_OK = 'CONNECTION_OK',
        NO_INFORMATION_AVAILABLE = 'NO_INFORMATION_AVAILABLE',
    }
    export enum connectionType {
        INTERCHANGE = 'INTERCHANGE',
        PROTECTED_CONNECTION = 'PROTECTED_CONNECTION',
    }
}

