import mapboxgl from 'mapbox-gl';

import { Place } from '../types/Place';

export class MapHelperService {
  public static getVisibility(visible: boolean) {
    return visible ? 'visible' : 'none';
  }

  public static compareCoordinatesAndMarker(place: Pick<Place, 'lng' | 'lat'>, marker: mapboxgl.Marker | undefined) {
    return place.lat === marker?.getLngLat().lat && place.lng === marker?.getLngLat().lng;
  }
}
