import React from 'react';
import { makeStyles } from '@mui/styles';

import { PlaceType } from '../types/PlaceType';
import { RoutingHeading } from '../components/RoutingHeading';
import { RoutingRequestService } from '../services/RoutingRequestService';
import { useStore } from '../setup/global-state';
import { GeocodingSearchInput } from './GeocodingSearchInput';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const RoutingForm = () => {
  const classes = useStyles();

  const origin = useStore((state) => state.places[PlaceType.ORIGIN]);
  const destination = useStore((state) => state.places[PlaceType.DESTINATION]);
  const updateRoutes = useStore((state) => state.updateRoutes);

  return (
    <form
      className={classes.root}
      onSubmit={async (event) => {
        event.preventDefault();

        if (origin && destination) {
          updateRoutes(await RoutingRequestService.requestRoute(origin, destination));
        }
      }}
    >
      <RoutingHeading>Wo mechst hi?</RoutingHeading>

      <GeocodingSearchInput label="Start" placeType={PlaceType.ORIGIN} />
      <GeocodingSearchInput label="Ziel" placeType={PlaceType.DESTINATION} />
    </form>
  );
};
