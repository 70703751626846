import React, { ReactNode } from 'react';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { StepPagination } from './StepPagination';
import { CustomDialogExample } from './CustomDialogExample';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  closeButton: {
    zIndex: 1,
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  closeIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  dialogTitle: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)} ${theme.spacing(8)}`,
    },
  },
  contentLarge: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)} ${theme.spacing(16)} ${theme.spacing(4)}`,
    },
  },
  contentCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
    maxWidth: theme.spacing(60),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  containerLeft: {
    [theme.breakpoints.up('md')]: {
      width: '50%',

      '& $paper': {
        marginLeft: theme.spacing(5.75),
        marginRight: theme.spacing(5.75),
      },
    },
  },
  containerRight: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      width: '50%',

      '& $paper': {
        width: '100%',
        marginLeft: theme.spacing(5.75),
        marginRight: theme.spacing(5.75),
      },
    },
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    maxWidth: theme.spacing(45),
    zIndex: 0,
  },
  imageLarge: {
    maxWidth: theme.spacing(60),
  },
  title: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    fontSize: '2rem',

    [theme.breakpoints.up('md')]: {
      fontSize: '2.5rem',
    },

    [theme.breakpoints.up('xl')]: {
      fontSize: '3.5rem',
    },
  },
  subTitle: {
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
  },
  actions: {
    display: 'block',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      right: 0,
      left: 0,
      top: theme.spacing(-2),
      height: theme.spacing(2),
      borderBottom: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
      backgroundImage: `linear-gradient(to top, ${alpha(theme.palette.primary.main, 0.738)} 19%, ${alpha(
        theme.palette.primary.main,
        0.541,
      )} 34%, ${alpha(theme.palette.primary.main, 0.382)} 47%, ${alpha(
        theme.palette.primary.main,
        0.278,
      )} 56.5%, ${alpha(theme.palette.primary.main, 0.194)} 65%, ${alpha(
        theme.palette.primary.main,
        0.126,
      )} 73%, ${alpha(theme.palette.primary.main, 0.075)} 80.2%, ${alpha(
        theme.palette.primary.main,
        0.042,
      )} 86.1%, ${alpha(theme.palette.primary.main, 0.021)} 91%, ${alpha(
        theme.palette.primary.main,
        0.008,
      )} 95.2%, ${alpha(theme.palette.primary.main, 0.002)} 98.2%, transparent 100%)`,
      pointerEvents: 'none',
    },

    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(8),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(8),
    },

    '& .MuiButton-root': {
      display: 'block',
      margin: '0 auto',
    },
  },
  actionsLarge: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  buttonNext: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  buttonSkip: {
    color: theme.palette.common.white,
    marginTop: `${theme.spacing(1)} !important`,
  },
}));

type CustomDialogProps = {
  open: boolean;
  onClose: () => void | ((event: HTMLButtonElement | undefined, reason: string) => void);
  onNext: () => void;
  onSkip?: () => void;
  position?: 'left' | 'center' | 'right';
  large?: boolean;
  image: string;
  imageAlt: string;
  title: string;
  subTitle?: string;
  content: string;
  beforeActionsContent?: ReactNode;
  nextLabel: string;
  skipLabel?: string;
  currentStep?: number;
  numberOfSteps?: number;
  exampleIcon?: ReactNode;
  exampleTitle?: string;
  exampleComponent?: ReactNode;
  examplePopper?: boolean;
  disableClose?: boolean;
  afterActionsContent?: ReactNode;
};

export const CustomDialog = ({
  open,
  onClose,
  onNext,
  onSkip,
  position = 'center',
  large = false,
  image,
  imageAlt,
  title,
  subTitle,
  content,
  beforeActionsContent,
  nextLabel,
  skipLabel,
  currentStep,
  numberOfSteps,
  exampleIcon,
  exampleTitle,
  exampleComponent,
  examplePopper,
  disableClose = false,
  afterActionsContent,
}: CustomDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      data-click-away-ignore
      fullScreen={matches}
      maxWidth="xl"
      classes={{
        paper: classes.paper,
        container: { left: classes.containerLeft, right: classes.containerRight, center: undefined }[position],
      }}
      onClose={onClose}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        {!disableClose && (
          <IconButton aria-label="Schließen" className={classes.closeButton} onClick={onClose} size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent className={clsx(classes.content, large && classes.contentLarge)}>
        <Box className={classes.contentCenter}>
          <img className={clsx(classes.image, classes.imageLarge)} src={image} alt={imageAlt} key={image} />
          <Typography variant="h1" className={classes.title} align="center">
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="h2" className={classes.subTitle} align="center">
              {subTitle}
            </Typography>
          )}
          <Typography align="center">{content}</Typography>

          <CustomDialogExample
            key={title}
            exampleIcon={exampleIcon}
            exampleTitle={exampleTitle}
            exampleComponent={exampleComponent}
            examplePopper={examplePopper}
          />
        </Box>
      </DialogContent>

      <DialogActions className={clsx(classes.actions, large && classes.contentLarge)} disableSpacing>
        {currentStep && numberOfSteps && <StepPagination currentStep={currentStep} numberOfSteps={numberOfSteps} />}

        {beforeActionsContent}

        <Button onClick={onNext} className={classes.buttonNext} variant="contained">
          {nextLabel}
        </Button>
        {onSkip && skipLabel && (
          <Button onClick={onSkip} className={classes.buttonSkip}>
            {skipLabel}
          </Button>
        )}

        {afterActionsContent}
      </DialogActions>
    </Dialog>
  );
};
