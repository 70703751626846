/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessibilityAssessmentStructure } from './AccessibilityAssessmentStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { LinkProjectionStructure } from './LinkProjectionStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { OffsetStructure } from './OffsetStructure';
import type { PointProjectionStructure } from './PointProjectionStructure';
import type { StopPlaceComponentRefStructure } from './StopPlaceComponentRefStructure';
import type { ZoneProjectionStructure } from './ZoneProjectionStructure';
export type AffectedStopPlaceComponentStructure = {
    accessibilityAssessment?: AccessibilityAssessmentStructure;
    componentName?: NaturalLanguageStringStructure;
    componentRef?: StopPlaceComponentRefStructure;
    componentType?: AffectedStopPlaceComponentStructure.componentType;
    extensions?: ExtensionsStructure;
    linkProjection?: LinkProjectionStructure;
    offset?: OffsetStructure;
    pointProjection?: PointProjectionStructure;
    zoneProjection?: ZoneProjectionStructure;
};
export namespace AffectedStopPlaceComponentStructure {
    export enum componentType {
        QUAY = 'QUAY',
        ACCESS_SPACE = 'ACCESS_SPACE',
        ENTRANCE = 'ENTRANCE',
        BOARDING_POSITION = 'BOARDING_POSITION',
        STOPPING_PLACE = 'STOPPING_PLACE',
    }
}

