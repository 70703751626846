/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralizedConnectionStructure } from './GeneralizedConnectionStructure';
import type { RecommendationStructure } from './RecommendationStructure';
export type ConnectionStatusStructure = {
    alternative?: Array<RecommendationStructure>;
    connection?: GeneralizedConnectionStructure;
    status?: ConnectionStatusStructure.status;
};
export namespace ConnectionStatusStructure {
    export enum status {
        UNKNOWN = 'UNKNOWN',
        PLANNED = 'PLANNED',
        CONFIRMED = 'CONFIRMED',
        BROKEN = 'BROKEN',
    }
}

