import React from 'react';
import { Route } from 'react-router-dom';

import { AboutPage } from './pages/AboutPage';
import { ContactPage } from './pages/ContactPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { SentryRoutes } from './setup/sentry';
import { EndOfProjectPage } from './pages/EndOfProjectPage';
import { StartPage } from './pages/StartPage';

export const App = () => (
  <SentryRoutes>
    <Route path="/" element={<EndOfProjectPage />} />
    <Route path="/karte" element={<StartPage />} />

    <Route path="/ueber-das-projekt" element={<AboutPage />} />
    <Route path="/kontakt" element={<ContactPage />} />
    <Route path="/datenschutz" element={<PrivacyPage />} />

    <Route path="*" element={<NotFoundPage />} />
  </SentryRoutes>
);
