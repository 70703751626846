/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionsStructure } from './ActionsStructure';
import type { AffectsScopeStructure } from './AffectsScopeStructure';
import type { CountryRefStructure } from './CountryRefStructure';
import type { DefaultedTextStructure } from './DefaultedTextStructure';
import type { EntryQualifierStructure } from './EntryQualifierStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { HalfOpenTimestampRangeStructure } from './HalfOpenTimestampRangeStructure';
import type { Images } from './Images';
import type { InfoLinks } from './InfoLinks';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { ParticipantRefStructure } from './ParticipantRefStructure';
import type { PtConsequencesStructure } from './PtConsequencesStructure';
import type { ReferencesStructure } from './ReferencesStructure';
import type { Repetitions } from './Repetitions';
import type { SecondaryReasons } from './SecondaryReasons';
import type { SituationSourceStructure } from './SituationSourceStructure';
import type { SituationVersion } from './SituationVersion';
import type { ValidityPeriod } from './ValidityPeriod';
export type PtSituationElementStructure = {
    advice?: DefaultedTextStructure;
    affects?: AffectsScopeStructure;
    audience?: PtSituationElementStructure.audience;
    consequences?: PtConsequencesStructure;
    countryRef?: CountryRefStructure;
    creationTime?: string;
    description?: DefaultedTextStructure;
    detail?: DefaultedTextStructure;
    environmentReason?: PtSituationElementStructure.environmentReason;
    equipmentReason?: PtSituationElementStructure.equipmentReason;
    extensions?: ExtensionsStructure;
    images?: Images;
    infoLinks?: InfoLinks;
    internal?: DefaultedTextStructure;
    keywords?: Array<string>;
    language?: string;
    likelihood?: PtSituationElementStructure.likelihood;
    miscellaneousReason?: PtSituationElementStructure.miscellaneousReason;
    participantRef?: ParticipantRefStructure;
    personnelReason?: PtSituationElementStructure.personnelReason;
    planned?: boolean;
    priority?: number;
    progress?: PtSituationElementStructure.progress;
    publicEventReason?: PtSituationElementStructure.publicEventReason;
    publication?: Array<string>;
    publicationWindow?: HalfOpenTimestampRangeStructure;
    publishingActions?: ActionsStructure;
    qualityIndex?: PtSituationElementStructure.qualityIndex;
    reality?: PtSituationElementStructure.reality;
    reasonName?: NaturalLanguageStringStructure;
    references?: ReferencesStructure;
    repetitions?: Repetitions;
    reportType?: string;
    scopeType?: PtSituationElementStructure.scopeType;
    secondaryReasons?: SecondaryReasons;
    sensitivity?: PtSituationElementStructure.sensitivity;
    severity?: PtSituationElementStructure.severity;
    situationNumber?: EntryQualifierStructure;
    source?: SituationSourceStructure;
    summary?: DefaultedTextStructure;
    undefinedReason?: string;
    unknownReason?: string;
    updateCountryRef?: CountryRefStructure;
    updateParticipantRef?: ParticipantRefStructure;
    validityPeriod?: Array<ValidityPeriod>;
    verification?: PtSituationElementStructure.verification;
    version?: SituationVersion;
    versionedAtTime?: string;
};
export namespace PtSituationElementStructure {
    export enum audience {
        PUBLIC = 'PUBLIC',
        EMERGENCY_SERVICES = 'EMERGENCY_SERVICES',
        STAFF = 'STAFF',
        STATION_STAFF = 'STATION_STAFF',
        MANAGEMENT = 'MANAGEMENT',
        INFO_SERVICES = 'INFO_SERVICES',
    }
    export enum environmentReason {
        PTI_22_0 = 'PTI_22_0',
        UNKNOWN = 'UNKNOWN',
        PTI_22_1 = 'PTI_22_1',
        FOG = 'FOG',
        PTI_22_2 = 'PTI_22_2',
        ROUGH_SEA = 'ROUGH_SEA',
        PTI_22_3 = 'PTI_22_3',
        HEAVY_SNOW_FALL = 'HEAVY_SNOW_FALL',
        PTI_22_3_ALIAS_1 = 'PTI_22_3_ALIAS_1',
        DRIFTING_SNOW = 'DRIFTING_SNOW',
        PTI_22_3_ALIAS_2 = 'PTI_22_3_ALIAS_2',
        BLIZZARD_CONDITIONS = 'BLIZZARD_CONDITIONS',
        PTI_22_4 = 'PTI_22_4',
        HEAVY_RAIN = 'HEAVY_RAIN',
        PTI_22_5 = 'PTI_22_5',
        STRONG_WINDS = 'STRONG_WINDS',
        PTI_22_5_ALIAS_1 = 'PTI_22_5_ALIAS_1',
        STORM_CONDITIONS = 'STORM_CONDITIONS',
        PTI_22_5_ALIAS_2 = 'PTI_22_5_ALIAS_2',
        STORM_DAMAGE = 'STORM_DAMAGE',
        PTI_22_6 = 'PTI_22_6',
        TIDAL_RESTRICTIONS = 'TIDAL_RESTRICTIONS',
        PTI_22_7 = 'PTI_22_7',
        HIGH_TIDE = 'HIGH_TIDE',
        PTI_22_8 = 'PTI_22_8',
        LOW_TIDE = 'LOW_TIDE',
        PTI_22_9 = 'PTI_22_9',
        ICE = 'ICE',
        PTI_22_10 = 'PTI_22_10',
        FROZEN = 'FROZEN',
        PTI_22_11 = 'PTI_22_11',
        HAIL = 'HAIL',
        PTI_22_11_ALIAS_1 = 'PTI_22_11_ALIAS_1',
        SLEET = 'SLEET',
        PTI_22_12 = 'PTI_22_12',
        HIGH_TEMPERATURES = 'HIGH_TEMPERATURES',
        PTI_22_13 = 'PTI_22_13',
        FLOODING = 'FLOODING',
        PTI_22_14 = 'PTI_22_14',
        WATERLOGGED = 'WATERLOGGED',
        PTI_22_15 = 'PTI_22_15',
        LOW_WATER_LEVEL = 'LOW_WATER_LEVEL',
        PTI_22_16 = 'PTI_22_16',
        HIGH_WATER_LEVEL = 'HIGH_WATER_LEVEL',
        PTI_22_17 = 'PTI_22_17',
        FALLEN_LEAVES = 'FALLEN_LEAVES',
        PTI_22_18 = 'PTI_22_18',
        FALLEN_TREE = 'FALLEN_TREE',
        PTI_22_19 = 'PTI_22_19',
        LANDSLIDE = 'LANDSLIDE',
        PTI_22_255 = 'PTI_22_255',
        UNDEFINED_ENVIRONMENTAL_PROBLEM = 'UNDEFINED_ENVIRONMENTAL_PROBLEM',
        PTI_22_255_ALIAS_1 = 'PTI_22_255_ALIAS_1',
        LIGHTNING_STRIKE = 'LIGHTNING_STRIKE',
        PTI_22_255_ALIAS_2 = 'PTI_22_255_ALIAS_2',
        SEWER_OVERFLOW = 'SEWER_OVERFLOW',
        PTI_22_255_ALIAS_3 = 'PTI_22_255_ALIAS_3',
        GRASS_FIRE = 'GRASS_FIRE',
    }
    export enum equipmentReason {
        PTI_21_0 = 'PTI_21_0',
        UNKNOWN = 'UNKNOWN',
        PTI_21_1 = 'PTI_21_1',
        POINTS_PROBLEM = 'POINTS_PROBLEM',
        PTI_21_2 = 'PTI_21_2',
        POINTS_FAILURE = 'POINTS_FAILURE',
        PTI_21_3 = 'PTI_21_3',
        SIGNAL_PROBLEM = 'SIGNAL_PROBLEM',
        PTI_21_3_ALIAS_1 = 'PTI_21_3_ALIAS_1',
        TRAIN_WARNING_SYSTEM_PROBLEM = 'TRAIN_WARNING_SYSTEM_PROBLEM',
        PTI_21_3_ALIAS_2 = 'PTI_21_3_ALIAS_2',
        TRACK_CIRCUIT_PROBLEM = 'TRACK_CIRCUIT_PROBLEM',
        PTI_21_4 = 'PTI_21_4',
        SIGNAL_FAILURE = 'SIGNAL_FAILURE',
        PTI_21_5 = 'PTI_21_5',
        DERAILMENT = 'DERAILMENT',
        PTI_21_6 = 'PTI_21_6',
        ENGINE_FAILURE = 'ENGINE_FAILURE',
        PTI_21_6_ALIAS_1 = 'PTI_21_6_ALIAS_1',
        TRACTION_FAILURE = 'TRACTION_FAILURE',
        PTI_21_7 = 'PTI_21_7',
        BREAK_DOWN = 'BREAK_DOWN',
        PTI_21_8 = 'PTI_21_8',
        TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
        PTI_21_8_ALIAS_1 = 'PTI_21_8_ALIAS_1',
        BROKEN_RAIL = 'BROKEN_RAIL',
        PTI_21_8_ALIAS_2 = 'PTI_21_8_ALIAS_2',
        POOR_RAIL_CONDITIONS = 'POOR_RAIL_CONDITIONS',
        PTI_21_8_ALIAS_3 = 'PTI_21_8_ALIAS_3',
        WHEEL_IMPACT_LOAD = 'WHEEL_IMPACT_LOAD',
        PTI_21_8_ALIAS_4 = 'PTI_21_8_ALIAS_4',
        LACK_OF_OPERATIONAL_STOCK = 'LACK_OF_OPERATIONAL_STOCK',
        PTI_21_8_ALIAS_5 = 'PTI_21_8_ALIAS_5',
        DEFECTIVE_FIRE_ALARM_EQUIPMENT = 'DEFECTIVE_FIRE_ALARM_EQUIPMENT',
        PTI_21_8_ALIAS_6 = 'PTI_21_8_ALIAS_6',
        DEFECTIVE_PLATFORM_EDGE_DOORS = 'DEFECTIVE_PLATFORM_EDGE_DOORS',
        PTI_21_8_ALIAS_7 = 'PTI_21_8_ALIAS_7',
        DEFECTIVE_CCTV = 'DEFECTIVE_CCTV',
        PTI_21_8_ALIAS_8 = 'PTI_21_8_ALIAS_8',
        DEFECTIVE_PUBLIC_ANNOUNCEMENT_SYSTEM = 'DEFECTIVE_PUBLIC_ANNOUNCEMENT_SYSTEM',
        PTI_21_8_ALIAS_9 = 'PTI_21_8_ALIAS_9',
        TICKETING_SYSTEM_NOT_AVAILABLE = 'TICKETING_SYSTEM_NOT_AVAILABLE',
        PTI_21_9 = 'PTI_21_9',
        REPAIR_WORK = 'REPAIR_WORK',
        PTI_21_10 = 'PTI_21_10',
        CONSTRUCTION_WORK = 'CONSTRUCTION_WORK',
        PTI_21_11 = 'PTI_21_11',
        MAINTENANCE_WORK = 'MAINTENANCE_WORK',
        PTI_21_11_ALIAS_1 = 'PTI_21_11_ALIAS_1',
        EMERGENCY_ENGINEERING_WORK = 'EMERGENCY_ENGINEERING_WORK',
        PTI_21_11_ALIAS_2 = 'PTI_21_11_ALIAS_2',
        LATE_FINISH_TO_ENGINEERING_WORK = 'LATE_FINISH_TO_ENGINEERING_WORK',
        PTI_21_12 = 'PTI_21_12',
        POWER_PROBLEM = 'POWER_PROBLEM',
        PTI_21_13 = 'PTI_21_13',
        FUEL_PROBLEM = 'FUEL_PROBLEM',
        PTI_21_14 = 'PTI_21_14',
        SWING_BRIDGE_FAILURE = 'SWING_BRIDGE_FAILURE',
        PTI_21_15 = 'PTI_21_15',
        ESCALATOR_FAILURE = 'ESCALATOR_FAILURE',
        PTI_21_16 = 'PTI_21_16',
        LIFT_FAILURE = 'LIFT_FAILURE',
        PTI_21_17 = 'PTI_21_17',
        GANGWAY_PROBLEM = 'GANGWAY_PROBLEM',
        PTI_21_18 = 'PTI_21_18',
        CLOSED_FOR_MAINTENANCE = 'CLOSED_FOR_MAINTENANCE',
        PTI_21_19 = 'PTI_21_19',
        FUEL_SHORTAGE = 'FUEL_SHORTAGE',
        PTI_21_20 = 'PTI_21_20',
        DEICING_WORK = 'DEICING_WORK',
        PTI_21_21 = 'PTI_21_21',
        WHEEL_PROBLEM = 'WHEEL_PROBLEM',
        PTI_21_22 = 'PTI_21_22',
        LUGGAGE_CAROUSEL_PROBLEM = 'LUGGAGE_CAROUSEL_PROBLEM',
        PTI_21_255 = 'PTI_21_255',
        UNDEFINED_EQUIPMENT_PROBLEM = 'UNDEFINED_EQUIPMENT_PROBLEM',
    }
    export enum likelihood {
        CERTAIN = 'CERTAIN',
        PROBABLE = 'PROBABLE',
        RISK_OF = 'RISK_OF',
        IMPROBABLE = 'IMPROBABLE',
    }
    export enum miscellaneousReason {
        PTI_19_0 = 'PTI_19_0',
        UNKNOWN = 'UNKNOWN',
        PTI_19_1 = 'PTI_19_1',
        INCIDENT = 'INCIDENT',
        PTI_19_1_ALIAS_1 = 'PTI_19_1_ALIAS_1',
        NEAR_MISS = 'NEAR_MISS',
        PTI_19_1_ALIAS_2 = 'PTI_19_1_ALIAS_2',
        SAFETY_VIOLATION = 'SAFETY_VIOLATION',
        PTI_19_1_ALIAS_3 = 'PTI_19_1_ALIAS_3',
        SIGNAL_PASSED_AT_DANGER = 'SIGNAL_PASSED_AT_DANGER',
        PTI_19_1_ALIAS_4 = 'PTI_19_1_ALIAS_4',
        STATION_OVERRUN = 'STATION_OVERRUN',
        PTI_19_1_ALIAS_5 = 'PTI_19_1_ALIAS_5',
        TRAIN_DOOR = 'TRAIN_DOOR',
        PTI_19_1_ALIAS_6 = 'PTI_19_1_ALIAS_6',
        EMERGENCY_SERVICES_CALL = 'EMERGENCY_SERVICES_CALL',
        PTI_19_2 = 'PTI_19_2',
        BOMB_EXPLOSION = 'BOMB_EXPLOSION',
        PTI_19_3 = 'PTI_19_3',
        SECURITY_ALERT = 'SECURITY_ALERT',
        PTI_19_3_ALIAS_1 = 'PTI_19_3_ALIAS_1',
        POLICE_REQUEST = 'POLICE_REQUEST',
        PTI_19_3_ALIAS_2 = 'PTI_19_3_ALIAS_2',
        FIRE_BRIGADE_SAFETY_CHECKS = 'FIRE_BRIGADE_SAFETY_CHECKS',
        PTI_19_3_ALIAS_3 = 'PTI_19_3_ALIAS_3',
        UNATTENDED_BAG = 'UNATTENDED_BAG',
        PTI_19_3_ALIAS_4 = 'PTI_19_3_ALIAS_4',
        TELEPHONED_THREAT = 'TELEPHONED_THREAT',
        PTI_19_3_ALIAS_5 = 'PTI_19_3_ALIAS_5',
        SUSPECT_VEHICLE = 'SUSPECT_VEHICLE',
        PTI_19_3_ALIAS_6 = 'PTI_19_3_ALIAS_6',
        CIVIL_EMERGENCY = 'CIVIL_EMERGENCY',
        PTI_19_3_ALIAS_7 = 'PTI_19_3_ALIAS_7',
        AIR_RAID = 'AIR_RAID',
        PTI_19_3_ALIAS_8 = 'PTI_19_3_ALIAS_8',
        SABOTAGE = 'SABOTAGE',
        PTI_19_3_ALIAS_9 = 'PTI_19_3_ALIAS_9',
        BOMB_ALERT = 'BOMB_ALERT',
        PTI_19_3_ALIAS_10 = 'PTI_19_3_ALIAS_10',
        ATTACH = 'ATTACH',
        PTI_19_3_ALIAS_11 = 'PTI_19_3_ALIAS_11',
        EVACUATION = 'EVACUATION',
        PTI_19_3_ALIAS_12 = 'PTI_19_3_ALIAS_12',
        TERRORIST_INCIDENT = 'TERRORIST_INCIDENT',
        PTI_19_3_ALIAS_13 = 'PTI_19_3_ALIAS_13',
        GUNFIRE_ON_ROADWAY = 'GUNFIRE_ON_ROADWAY',
        PTI_19_3_ALIAS_14 = 'PTI_19_3_ALIAS_14',
        EXPLOSION = 'EXPLOSION',
        PTI_19_3_ALIAS_15 = 'PTI_19_3_ALIAS_15',
        EXPLOSION_HAZARD = 'EXPLOSION_HAZARD',
        PTI_19_3_ALIAS_16 = 'PTI_19_3_ALIAS_16',
        SECURITY_INCIDENT = 'SECURITY_INCIDENT',
        PTI_19_4 = 'PTI_19_4',
        FIRE = 'FIRE',
        PTI_19_4_ALIAS_1 = 'PTI_19_4_ALIAS_1',
        LINESIDE_FIRE = 'LINESIDE_FIRE',
        PTI_19_5 = 'PTI_19_5',
        VANDALISM = 'VANDALISM',
        PTI_19_5_ALIAS_1 = 'PTI_19_5_ALIAS_1',
        PASSENGER_ACTION = 'PASSENGER_ACTION',
        PTI_19_5_ALIAS_2 = 'PTI_19_5_ALIAS_2',
        STAFF_ASSAULT = 'STAFF_ASSAULT',
        PTI_19_5_ALIAS_3 = 'PTI_19_5_ALIAS_3',
        RAILWAY_CRIME = 'RAILWAY_CRIME',
        PTI_19_6 = 'PTI_19_6',
        ACCIDENT = 'ACCIDENT',
        PTI_19_6_ALIAS_1 = 'PTI_19_6_ALIAS_1',
        FATALITY = 'FATALITY',
        PTI_19_6_ALIAS_2 = 'PTI_19_6_ALIAS_2',
        PERSON_UNDER_TRAIN = 'PERSON_UNDER_TRAIN',
        PTI_19_6_ALIAS_3 = 'PTI_19_6_ALIAS_3',
        PERSON_HIT_BY_TRAIN = 'PERSON_HIT_BY_TRAIN',
        PTI_19_6_ALIAS_4 = 'PTI_19_6_ALIAS_4',
        PERSON_ILL_ON_VEHICLE = 'PERSON_ILL_ON_VEHICLE',
        PTI_19_6_ALIAS_5 = 'PTI_19_6_ALIAS_5',
        EMERGENCY_SERVICES = 'EMERGENCY_SERVICES',
        PTI_19_6_ALIAS_6 = 'PTI_19_6_ALIAS_6',
        COLLISION = 'COLLISION',
        PTI_19_7 = 'PTI_19_7',
        OVERCROWDED = 'OVERCROWDED',
        PTI_19_8 = 'PTI_19_8',
        INSUFFICIENT_DEMAND = 'INSUFFICIENT_DEMAND',
        PTI_19_9 = 'PTI_19_9',
        LIGHTING_FAILURE = 'LIGHTING_FAILURE',
        PTI_19_10 = 'PTI_19_10',
        LEADER_BOARD_FAILURE = 'LEADER_BOARD_FAILURE',
        PTI_19_11 = 'PTI_19_11',
        SERVICE_INDICATOR_FAILURE = 'SERVICE_INDICATOR_FAILURE',
        PTI_19_12 = 'PTI_19_12',
        SERVICE_FAILURE = 'SERVICE_FAILURE',
        PTI_19_13 = 'PTI_19_13',
        OPERATOR_CEASED_TRADING = 'OPERATOR_CEASED_TRADING',
        PTI_19_14 = 'PTI_19_14',
        OPERATOR_SUSPENDED = 'OPERATOR_SUSPENDED',
        PTI_19_15 = 'PTI_19_15',
        CONGESTION = 'CONGESTION',
        PTI_19_16 = 'PTI_19_16',
        ROUTE_BLOCKAGE = 'ROUTE_BLOCKAGE',
        PTI_19_17 = 'PTI_19_17',
        PERSON_ON_THE_LINE = 'PERSON_ON_THE_LINE',
        PTI_19_18 = 'PTI_19_18',
        VEHICLE_ON_THE_LINE = 'VEHICLE_ON_THE_LINE',
        PTI_19_18_ALIAS_1 = 'PTI_19_18_ALIAS_1',
        LEVEL_CROSSING_INCIDENT = 'LEVEL_CROSSING_INCIDENT',
        PTI_19_19 = 'PTI_19_19',
        OBJECT_ON_THE_LINE = 'OBJECT_ON_THE_LINE',
        PTI_19_19_ALIAS_1 = 'PTI_19_19_ALIAS_1',
        FALLEN_TREE_ON_THE_LINE = 'FALLEN_TREE_ON_THE_LINE',
        PTI_19_19_ALIAS_2 = 'PTI_19_19_ALIAS_2',
        VEGETATION = 'VEGETATION',
        PTI_19_19_ALIAS_3 = 'PTI_19_19_ALIAS_3',
        TRAIN_STRUCK_ANIMAL = 'TRAIN_STRUCK_ANIMAL',
        PTI_19_19_ALIAS_4 = 'PTI_19_19_ALIAS_4',
        TRAIN_STRUCK_OBJECT = 'TRAIN_STRUCK_OBJECT',
        PTI_19_20 = 'PTI_19_20',
        ANIMAL_ON_THE_LINE = 'ANIMAL_ON_THE_LINE',
        PTI_19_21 = 'PTI_19_21',
        ROUTE_DIVERSION = 'ROUTE_DIVERSION',
        PTI_19_22 = 'PTI_19_22',
        ROAD_CLOSED = 'ROAD_CLOSED',
        PTI_19_23 = 'PTI_19_23',
        ROADWORKS = 'ROADWORKS',
        PTI_19_24 = 'PTI_19_24',
        SPECIAL_EVENT = 'SPECIAL_EVENT',
        PTI_19_24_ALIAS_1 = 'PTI_19_24_ALIAS_1',
        MARCH = 'MARCH',
        PTI_19_24_ALIAS_2 = 'PTI_19_24_ALIAS_2',
        PROCESSION = 'PROCESSION',
        PTI_19_24_ALIAS_3 = 'PTI_19_24_ALIAS_3',
        DEMONSTRATION = 'DEMONSTRATION',
        PTI_19_24_ALIAS_4 = 'PTI_19_24_ALIAS_4',
        PUBLIC_DISTURBANCE = 'PUBLIC_DISTURBANCE',
        PTI_19_24_ALIAS_5 = 'PTI_19_24_ALIAS_5',
        FILTER_BLOCKADE = 'FILTER_BLOCKADE',
        PTI_19_24_ALIAS_6 = 'PTI_19_24_ALIAS_6',
        SIGHTSEERS_OBSTRUCTING_ACCESS = 'SIGHTSEERS_OBSTRUCTING_ACCESS',
        PTI_19_25 = 'PTI_19_25',
        BRIDGE_STRIKE = 'BRIDGE_STRIKE',
        PTI_19_26 = 'PTI_19_26',
        OVERHEAD_OBSTRUCTION = 'OVERHEAD_OBSTRUCTION',
        PTI_19_27 = 'PTI_19_27',
        UNDEFINED_PROBLEM = 'UNDEFINED_PROBLEM',
        PTI_19_255_ALIAS_1 = 'PTI_19_255_ALIAS_1',
        PROBLEMS_AT_BORDER_POST = 'PROBLEMS_AT_BORDER_POST',
        PTI_19_255_ALIAS_2 = 'PTI_19_255_ALIAS_2',
        PROBLEMS_AT_CUSTOMS_POST = 'PROBLEMS_AT_CUSTOMS_POST',
        PTI_19_255_ALIAS_3 = 'PTI_19_255_ALIAS_3',
        PROBLEMS_ON_LOCAL_ROAD = 'PROBLEMS_ON_LOCAL_ROAD',
    }
    export enum personnelReason {
        PTI_20_0 = 'PTI_20_0',
        UNKNOWN = 'UNKNOWN',
        PTI_20_1 = 'PTI_20_1',
        STAFF_SICKNESS = 'STAFF_SICKNESS',
        PTI_20_1_ALIAS_1 = 'PTI_20_1_ALIAS_1',
        STAFF_INJURY = 'STAFF_INJURY',
        PTI_20_1_ALIAS_2 = 'PTI_20_1_ALIAS_2',
        CONTRACTOR_STAFF_INJURY = 'CONTRACTOR_STAFF_INJURY',
        PTI_20_2 = 'PTI_20_2',
        STAFF_ABSENCE = 'STAFF_ABSENCE',
        PTI_20_3 = 'PTI_20_3',
        STAFF_IN_WRONG_PLACE = 'STAFF_IN_WRONG_PLACE',
        PTI_20_4 = 'PTI_20_4',
        STAFF_SHORTAGE = 'STAFF_SHORTAGE',
        PTI_20_5 = 'PTI_20_5',
        INDUSTRIAL_ACTION = 'INDUSTRIAL_ACTION',
        PTI_20_5_ALIAS_1 = 'PTI_20_5_ALIAS_1',
        UNOFFICIAL_INDUSTRIAL_ACTION = 'UNOFFICIAL_INDUSTRIAL_ACTION',
        PTI_20_6 = 'PTI_20_6',
        WORK_TO_RULE = 'WORK_TO_RULE',
        PTI_20_255 = 'PTI_20_255',
        UNDEFINED_PERSONNEL_PROBLEM = 'UNDEFINED_PERSONNEL_PROBLEM',
    }
    export enum progress {
        DRAFT = 'DRAFT',
        APPROVED_DRAFT = 'APPROVED_DRAFT',
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
        CLOSING = 'CLOSING',
        CLOSED = 'CLOSED',
    }
    export enum publicEventReason {
        ATHLETICS_MEETING = 'ATHLETICS_MEETING',
        BALL_GAME = 'BALL_GAME',
        BASEBALL_GAME = 'BASEBALL_GAME',
        BASKETBALL_GAME = 'BASKETBALL_GAME',
        BICYCLE_RACE = 'BICYCLE_RACE',
        BOAT_RACE = 'BOAT_RACE',
        BOXING_TOURNAMENT = 'BOXING_TOURNAMENT',
        BULL_FIGHT = 'BULL_FIGHT',
        CEREMONIAL_EVENT = 'CEREMONIAL_EVENT',
        CONCERT = 'CONCERT',
        CRICKET_MATCH = 'CRICKET_MATCH',
        EXHIBITION = 'EXHIBITION',
        FAIR = 'FAIR',
        FESTIVAL = 'FESTIVAL',
        FILM_TV_MAKING = 'FILM_TV_MAKING',
        FOOTBALL_MATCH = 'FOOTBALL_MATCH',
        FUNFAIR = 'FUNFAIR',
        GOLF_TOURNAMENT = 'GOLF_TOURNAMENT',
        HOCKEY_GAME = 'HOCKEY_GAME',
        HORSE_RACE_MEETING = 'HORSE_RACE_MEETING',
        INTERNATIONAL_SPORTS_MEETING = 'INTERNATIONAL_SPORTS_MEETING',
        MAJOR_EVENT = 'MAJOR_EVENT',
        MARATHON = 'MARATHON',
        MARKET = 'MARKET',
        MATCH = 'MATCH',
        MOTOR_SPORT_RACE_MEETING = 'MOTOR_SPORT_RACE_MEETING',
        PARADE = 'PARADE',
        RACE_MEETING = 'RACE_MEETING',
        RUGBY_MATCH = 'RUGBY_MATCH',
        SEVERAL_MAJOR_EVENTS = 'SEVERAL_MAJOR_EVENTS',
        SHOW = 'SHOW',
        SHOW_JUMPING = 'SHOW_JUMPING',
        SPORTS_MEETING = 'SPORTS_MEETING',
        STATE_OCCASION = 'STATE_OCCASION',
        TENNIS_TOURNAMENT = 'TENNIS_TOURNAMENT',
        TOURNAMENT = 'TOURNAMENT',
        TRADE_FAIR = 'TRADE_FAIR',
        WATER_SPORTS_MEETING = 'WATER_SPORTS_MEETING',
        WINTER_SPORTS_MEETING = 'WINTER_SPORTS_MEETING',
        OTHER = 'OTHER',
    }
    export enum qualityIndex {
        CERTAIN = 'CERTAIN',
        VERY_RELIABLE = 'VERY_RELIABLE',
        RELIABLE = 'RELIABLE',
        PROBABLY_RELIABLE = 'PROBABLY_RELIABLE',
        UNCONFIRMED = 'UNCONFIRMED',
    }
    export enum reality {
        REAL = 'REAL',
        SECURITY_EXERCISE = 'SECURITY_EXERCISE',
        TECHNICAL_EXERCISE = 'TECHNICAL_EXERCISE',
        TEST = 'TEST',
    }
    export enum scopeType {
        GENERAL = 'GENERAL',
        OPERATOR = 'OPERATOR',
        NETWORK = 'NETWORK',
        ROUTE = 'ROUTE',
        LINE = 'LINE',
        PLACE = 'PLACE',
        STOP_PLACE = 'STOP_PLACE',
        STOP_PLACE_COMPONENT = 'STOP_PLACE_COMPONENT',
        STOP_POINT = 'STOP_POINT',
        VEHICLE_JOURNEY = 'VEHICLE_JOURNEY',
        DATED_VEHICLE_JOURNEY = 'DATED_VEHICLE_JOURNEY',
        CONNECTION_LINK = 'CONNECTION_LINK',
        INTERCHANGE = 'INTERCHANGE',
        ROAD = 'ROAD',
    }
    export enum sensitivity {
        VERY_HIGH = 'VERY_HIGH',
        HIGH = 'HIGH',
        MEDIUM = 'MEDIUM',
        LOW = 'LOW',
        VERY_LOW = 'VERY_LOW',
    }
    export enum severity {
        PTI_26_0 = 'PTI_26_0',
        UNKNOWN = 'UNKNOWN',
        PTI_26_1 = 'PTI_26_1',
        VERY_SLIGHT = 'VERY_SLIGHT',
        PTI_26_2 = 'PTI_26_2',
        SLIGHT = 'SLIGHT',
        PTI_26_3 = 'PTI_26_3',
        NORMAL = 'NORMAL',
        PTI_26_4 = 'PTI_26_4',
        SEVERE = 'SEVERE',
        PTI_26_5 = 'PTI_26_5',
        VERY_SEVERE = 'VERY_SEVERE',
        PTI_26_6 = 'PTI_26_6',
        NO_IMPACT = 'NO_IMPACT',
        PTI_26_255 = 'PTI_26_255',
        UNDEFINED = 'UNDEFINED',
    }
    export enum verification {
        PTI_32_0 = 'PTI_32_0',
        UNKNOWN = 'UNKNOWN',
        PTI_32_1 = 'PTI_32_1',
        UNVERIFIED = 'UNVERIFIED',
        PTI_32_255 = 'PTI_32_255',
        VERIFIED = 'VERIFIED',
        VERIFIED_AS_DUPLICATE = 'VERIFIED_AS_DUPLICATE',
    }
}

