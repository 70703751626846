import React, { ReactChild } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { HeaderNavigation } from './HeaderNavigation';
import { Footer } from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(22),
    },
  },
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
}));

type ContentPageProps = { children: ReactChild; containerClassName?: string };

export const ContentPageWrapper = ({ children, containerClassName }: ContentPageProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <HeaderNavigation />

      <Box className={classes.content}>
        <Container component="main" className={clsx(classes.container, containerClassName)}>
          {children}
        </Container>

        <Footer align="bottom" />
      </Box>
    </Box>
  );
};
