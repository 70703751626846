import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useStore } from '../setup/global-state';
import { ReactComponent as PlayButton } from '../assets/icons/play.svg';
import { ReactComponent as PauseButton } from '../assets/icons/pause.svg';
import { ControlsSection } from './ControlsSection';
import { CustomSliderWrapper } from './CustomSliderWrapper';
import { CustomSlider } from './CustomSlider';
import { CustomSwitch } from './CustomSwitch';
import { RainRadarLegend } from './RainRadarLegend';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginBottom: theme.spacing(0.75),
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
  },
}));

export const RainRadarControls = () => {
  const classes = useStyles();

  const rainRadarVisible = useStore((state) => state.rainRadarVisible);

  const rainRadarOffset = useStore((state) => state.rainRadarOffset);
  const rainRadarPlaying = useStore((state) => state.rainRadarPlaying);
  const updateRainRadarPlaying = useStore((state) => state.setRainRadarPlaying);
  const updateRainRadarOffset = useStore((state) => state.setRainRadarOffset);

  const toggleRainRadarVisible = useStore((state) => state.toggleRainRadarVisible);
  const toggleRainRadarPlaying = useStore((state) => state.toggleRainRadarPlaying);

  return (
    <>
      <ControlsSection>
        <CustomSliderWrapper label="Niederschlagsprognose">
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs="auto">
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  toggleRainRadarPlaying();

                  if (!rainRadarVisible) {
                    toggleRainRadarVisible();
                  }
                }}
                size="large"
              >
                {rainRadarPlaying ? <PauseButton /> : <PlayButton />}
              </IconButton>
            </Grid>
            <Grid item style={{ flex: '1 0 auto' }}>
              <CustomSlider
                value={rainRadarOffset}
                min={-120}
                max={120}
                step={5}
                onChange={(value: number) => {
                  if (!rainRadarVisible) {
                    toggleRainRadarVisible();
                  }
                  updateRainRadarPlaying(false);
                  updateRainRadarOffset(value);
                }}
                valueLabelFormat={(value: number) => (value === 0 ? 'Jetzt' : `${value > 0 ? '+' : ''}${value} min`)}
              />
            </Grid>
          </Grid>
        </CustomSliderWrapper>
      </ControlsSection>

      <ControlsSection>
        <CustomSwitch
          name="rain-radar-visible"
          label="Niederschlag einblenden"
          checked={rainRadarVisible}
          onChange={() => {
            toggleRainRadarVisible();
            updateRainRadarPlaying(false);
          }}
        />
      </ControlsSection>

      <ControlsSection>
        <RainRadarLegend />
      </ControlsSection>
    </>
  );
};
