import React from 'react';
import { Box, Button, CircularProgress, List, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { PlaceType } from '../types/PlaceType';
import { RoutingHeading } from '../components/RoutingHeading';
import { RouteType } from '../types/RouteType';
import { ReactComponent as RouteIcon } from '../assets/icons/forms/route.svg';
import { useStore } from '../setup/global-state';
import { RouteSelectItem } from '../components/RouteSelectItem';
import { TriasService } from '../services/TriasService';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: 0,

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(24.5),
  },
  newRouteButton: {
    backgroundColor: '#DFF7FD',
    color: theme.palette.primary.main,
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#DFF7FD',
    },
  },
  textRoute: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '60%',
    maxWidth: '100%',
    paddingBottom: theme.spacing(0.25),
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  textRouteIcon: {
    display: 'block',
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    width: theme.spacing(5),
    height: 'auto',
    flex: '0 0 auto',
  },
  textRoutePlaces: {
    overflow: 'hidden',
  },
  textRoutePlace: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const RouteSelect = () => {
  const classes = useStyles();

  const origin = useStore((state) => state.places[PlaceType.ORIGIN]);
  const destination = useStore((state) => state.places[PlaceType.DESTINATION]);
  const routes = useStore((state) => state.routes);
  const resetRouting = useStore((state) => state.resetRouting);

  const recommendedRoute = TriasService.getRouteFromTrias(routes[RouteType.RECOMMENDED]);
  const fastestRoute = TriasService.getRouteFromTrias(routes[RouteType.FASTEST]);

  return (
    <>
      <RoutingHeading>Do gehts lang:</RoutingHeading>

      <Box className={classes.textRoute}>
        <RouteIcon className={classes.textRouteIcon} />
        <Box className={classes.textRoutePlaces}>
          <Box className={classes.textRoutePlace}>{origin?.name.full}</Box>
          <Box className={classes.textRoutePlace}>{destination?.name.full}</Box>
        </Box>
      </Box>

      {recommendedRoute && fastestRoute && (
        <List className={classes.list}>
          {[RouteType.RECOMMENDED, RouteType.FASTEST].map((routeType) => (
            <RouteSelectItem
              key={routeType}
              routeType={routeType}
              route={{ [RouteType.RECOMMENDED]: recommendedRoute, [RouteType.FASTEST]: fastestRoute }[routeType]}
            />
          ))}
        </List>
      )}

      {((routes[RouteType.RECOMMENDED] && !routes[RouteType.RECOMMENDED]?.serviceDelivery?.status) ||
        (routes[RouteType.FASTEST] && !routes[RouteType.FASTEST]?.serviceDelivery?.status)) && (
        <Box className={clsx(classes.loading, classes.list)}>
          <Alert severity="warning">Keine Route gefunden</Alert>
        </Box>
      )}

      {!routes[RouteType.RECOMMENDED] && !routes[RouteType.FASTEST] && (
        <Box className={clsx(classes.loading, classes.list)}>
          <CircularProgress size={80} />
        </Box>
      )}

      <Button className={classes.newRouteButton} variant="contained" onClick={resetRouting}>
        Neue Route
      </Button>
    </>
  );
};
